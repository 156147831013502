{
  "name": "new-biblioteca-digital",
  "version": "1.1.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 4202",
    "build": "ng build --configuration development",
    "build-prod": "ng build --configuration production",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.0",
    "@angular/cdk": "^18.1.0",
    "@angular/cli": "^18.2.4",
    "@angular/common": "^18.0.0",
    "@angular/compiler": "^18.0.0",
    "@angular/core": "^18.0.0",
    "@angular/forms": "^18.0.0",
    "@angular/material": "^18.1.0",
    "@angular/platform-browser": "^18.0.0",
    "@angular/platform-browser-dynamic": "^18.0.0",
    "@angular/router": "^18.0.0",
    "@types/clevertap-web-sdk": "^1.1.11",
    "clevertap-web-sdk": "^1.9.6",
    "flag-icons": "^7.2.3",
    "moment": "^2.30.1",
    "rxjs": "~7.8.0",
    "swiper": "^11.1.5",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.4",
    "@angular/compiler-cli": "^18.0.0",
    "@types/jasmine": "~5.1.0",
    "cypress": "^13.13.2",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "tailwindcss": "^3.4.4",
    "typescript": "~5.4.2"
  }
}
