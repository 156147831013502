
<div class="body mx-auto min-h-screen overflow-hidden bg-contain bg-right bg-no-repeat bg-[url(assets/structure/imagenDerecha.png)]">
  <header>
    <nav class="mx-auto flex max-w-9xl items-center justify-between p-2 lg:px-8" aria-label="Global">
      <div class="section-logo flex lg:flex-1">
        <a href="#" class="-m-1.5 p-1.5">
          <span class="sr-only">Hola Doc</span>
          <img 
            class="logo-header h-17 w-auto" 
            src="assets/holaDocLogo.png" 
            alt="Hola Doc">
        </a>
      </div>
    </nav>
  </header>

  <div class="body-content flex flex-row mt-7">
    <div class="left-section w-1/2 ml-20">
      <div class="flex justify-center">
        <div class="w-10/12 flex flex-col items-start">

          <h1 class="header text-5xl font-bold sm:mb-9 lg:mb-8 text-headers-blue text-left leading-10 text-[41px]">
            ¡Impulsa tu Bienestar!
          </h1>

          <p class="paragraph text-sub-headers-gray text-left sm:mb-14 text-[20px] lg:mb-5">
            Descubre nuestros recursos digitales y avanza hacia una vida más saludable y activa
          </p>

          <div class="right-section-mobile w-full mb-14 rounded-[3px]">

          <swiper-container 
            id="swiperContainerMobile"  
            class="mySwiper" 
            pagination="false" 
            navigation="true"
            slides-per-view="3"
            centered-slides="true" 
            space-between="10" 
            grab-cursor="true"
            initial-slide="1"
          >
          
          <swiper-slide *ngFor="let cover of digitalAssets" class="rounded-lg">
            <img width="" [src]="assetsUrl + '/' + cover.img" [alt]="cover.name" class="rounded-lg">
          </swiper-slide>
          
          </swiper-container>
            
            <div class="flex flex-row justify-end gap-8 mr-8 navigation-buttons" style="transform: translate(38px, 15px); z-index: 1; position: relative;">
              <img width="30" src="assets/home/leftArrow.png" alt="left" id="leftArrow" (click)="navigateClickMobile('swiper-button-prev')" style="cursor: pointer;">
              <img width="30" src="assets/home/rigthArrow.png" alt="right" id="rigthArrow" (click)="navigateClickMobile('swiper-button-next')" style="cursor: pointer;">
            </div>

        </div>


          <div class="w-full" style="display: inherit;">
            <form
              [formGroup]="subscriberNgForm"
              class="bg-white rounded-[25px] px-8 pt-6 pb-8 mb-4 form-shadow w-[567px]">
              <div class="mb-3">
                <mat-form-field id="selectEbook" class="w-full justify-start bg-gray-100 rounded shadow" appearance="fill" subscriptSizing="fixed">
                  
                  @if ( labelDigitalResource ) {
                    <mat-label class="flex label-select-resource ml-6">
                      <img src="assets/logos/select_arrow_down.svg" alt="VZLA"> &nbsp;
                      <span>Selecciona un recurso</span>
                    </mat-label>
                  } 

                  <mat-select [value]="whyDigitalResource">

                    @for (dg of digitalAssets; track $index) {
                      <mat-option 
                      [attr.data-itemvalue]="dg._id" #optionRef (click)="itemSeleccionado(optionRef.value)"
                      [value]="dg._id">{{dg.name}}
                      </mat-option>
                    }

                  </mat-select>
                </mat-form-field>
                  <div class="error" *ngIf="!SelectDigitalResource">
                    Selecciona una opción
                  </div>
              </div>


              <div class="div-input-container flex flex-row justify-between gap-5">
                <div class="div-input mb-5 w-1/2 inputForm">
                  <label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
                    Nombre
                  </label>
                  <input
                    formControlName="firstName"
                    maxlength="50"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-8 focus:outline-none focus:shadow-outline"
                    id="nombre" type="text" placeholder="Nombre" 
                    [class.errorInput]="subscriberNgForm.get('firstName')!.hasError('required') && (subscriberNgForm.get('firstName')!.dirty || subscriberNgForm.get('firstName')!.touched)" >

                  <div class="error" *ngIf="subscriberNgForm.get('firstName')!.hasError('required') && (subscriberNgForm.get('firstName')!.dirty || subscriberNgForm.get('firstName')!.touched)">
                    Ingresa tu nombre
                  </div>
                </div>

                <div class="div-input mb-5 w-1/2 inputForm">
                  <label class="block text-gray-700 text-sm font-bold mb-2" for="apellido">
                    Apellido
                  </label>
                  <input maxlength="50" formControlName="lastName" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-8 focus:outline-none focus:shadow-outline"
                  id="apellido" type="text" placeholder="Apellido"
                  [class.errorInput]="subscriberNgForm.get('lastName')!.hasError('required') && (subscriberNgForm.get('lastName')!.dirty || subscriberNgForm.get('lastName')!.touched)"
                    >

                  <div class="error" *ngIf="subscriberNgForm.get('lastName')!.hasError('required') && (subscriberNgForm.get('lastName')!.dirty || subscriberNgForm.get('lastName')!.touched)">
                    Ingresa tu apellido
                  </div>
                </div>
              </div>

              <div class="mb-5 inputForm" (click)="$event.stopPropagation()">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="numero">
                  Número de teléfono
                </label>

                <div class="flex flex-row justify-between gap-5 selectFlagNumber">
                  <div class="w-1/6 selectFlag">
                    <mat-form-field id="selectEbookFlag" class="w-full justify-start bg-gray-100 shadow rounded-[5px] h-[45px] appearance-none" floatLabel="auto">
                      
                      <mat-icon 
                        matPrefix
                        [class]="selectedFlag == 've' ? 'stylesFlagVe' : 'stylesFlagForeign'"
                        >
                        <span
                          class="flag-icon flag-icon-squared flag-icon-rounded fi fi-{{selectedFlag}}"
                          style="transform: scale(0.5);"
                          >
                        </span>
                        <img *ngIf="selectedFlag == 've' " class="w-[15%]" src="assets/logos/select_arrow_down.svg" alt="VZLA">
                      </mat-icon>

                      <mat-select panelWidth="250px" hideSingleSelectionIndicator="false" stop>
                        <mat-select-trigger *ngIf="selectedFlag" class="selected-flag" style=" display: none; justify-content: center;">
                          <img class="w-[60%]" [src]="selectedFlag" alt="test">
                        </mat-select-trigger>

                        <input 
                          type="text" 
                          id="inputSelect" 
                          matInput
                          class="w-full border-none" 
                          placeholder="Buscar"
                          [formControl]="mycontrol"
                          (click)="$event.stopPropagation()"
                        >
                          @for (flag of filteredOptions | async ; track $index) {
                            <mat-option 
                              [attr.data-itemvalue]="flag.value"
                              #optionRef 
                              (click)="selectCountry(optionRef.value, flag.newSvgIcon, $event, flag.id)"
                              [value]="flag.phoneCode" 
                              [selected]="flag.value === '+355'"
                              class="flex flex-col">
                              <mat-icon matPrefix>
                                <span class="fi fi-{{flag.newSvgIcon}}"></span> 
                              </mat-icon>
                              <span> {{ flag.name }} {{ flag.phoneCode }} </span> 
                            </mat-option>
                          }
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="w-10/12 h-max inputForm inputFlagNumber">
                    <input formControlName="phoneNumber" class="[&::-webkit-inner-spin-button]:appearance-none phoneNumber shadow appearance-none borde rounded w-full py-2 px-3 text-gray-700 leading-8 focus:outline-none focus:shadow-outline"
                      id="numero" type="number" required placeholder="123465798"
                      [class.errorInput]="subscriberNgForm.get('phoneNumber')!.hasError('required') && (subscriberNgForm.get('phoneNumber')!.dirty || subscriberNgForm.get('phoneNumber')!.touched)"
                      >
                      <div class="error error-telefono" *ngIf="subscriberNgForm.get('phoneNumber')!.hasError('required') && (subscriberNgForm.get('phoneNumber')!.dirty || subscriberNgForm.get('phoneNumber')!.touched)">
                        Ingresa tu teléfono
                      </div>
                  </div>

                </div>

              </div>

              <div class="mb-5 inputForm">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="cedula">
                  Cédula de identidad
                </label>
                <input 
                  formControlName="identificationNumber" 
                  class="[&::-webkit-inner-spin-button]:appearance-none shadow appearance-none border rounded w-full px-3 text-gray-700 leading-8 focus:outline-none focus:shadow-outline"
                  id="cedula" 
                  type="text" 
                  placeholder="123456789"
                  (keydown)="validateNumber($event)"
                  (blur)="onBlur($event)"
                  [class.errorInput]=" (subscriberNgForm.get('identificationNumber')!.hasError('required') && 
                    (subscriberNgForm.get('identificationNumber')!.dirty 
                    || subscriberNgForm.get('identificationNumber')!.touched 
                    ) ) || subscriberNgForm.get('identificationNumber')!.hasError('lengthLocal') || subscriberNgForm.get('identificationNumber')!.hasError('lengthForeign')
                    "
                >
                <!-- (keypress)=" validateLength($event, 'identificationNumber') " -->

                <div class="error" *ngIf="subscriberNgForm.get('identificationNumber')!.hasError('required') && (subscriberNgForm.get('identificationNumber')!.dirty || subscriberNgForm.get('identificationNumber')!.touched)">
                  Ingresa tu número de cédula
                </div>

                <div class="error" *ngIf="subscriberNgForm.get('identificationNumber')!.hasError('lengthLocal')">
                  Debe tener mínimo 5 y máximo 9 dígitos
                </div>

                <div class="error" *ngIf="subscriberNgForm.get('identificationNumber')!.hasError('lengthForeign')">
                  Debe tener mínimo 1 y máximo 50 dígitos
                </div>

              </div>

              <div class="mb-5 form inputForm">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
                  Correo electronico
                </label>
                <input  formControlName="email" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-8 focus:outline-none focus:shadow-outline"
                  id="email" type="email" required placeholder="correo@gmail.com"
                  [class.errorInput]="subscriberNgForm.get('email')!.hasError('required') && (subscriberNgForm.get('email')!.dirty || subscriberNgForm.get('email')!.touched)"
                  >

                  <div class="error" *ngIf="subscriberNgForm.get('email')!.hasError('required') && (subscriberNgForm.get('email')!.dirty || subscriberNgForm.get('email')!.touched)">
                    Ingresa tu correo electrónico
                  </div>
              </div>

              <div class="section-button flex items-center justify-center mb-5">
                <button [disabled]="!sendForm"  [class.buttonDisabled]="!sendForm" (click)="sendEmail()" class="bg-submit-botton hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline w-full" type="button">
                  <p class="p-1">
                    ¡Descargar recurso!
                  </p>
                </button>
              </div>
              <!-- (change)="cheked($event)" -->
              <div class="flex items-center terms-form-inside mt-8">
                <input formControlName="check" id="link-checkbox" type="checkbox" value="" class="w-4 h-4 rounded-sm dark:ring-offset-gray-800 focus:ring-transparent bg-gray-100 dark:border-gray-600">
                <label for="link-checkbox" class="ms-2 text-[64%] text-justify font-medium text-[#6D6B6B]"> Al enviar el formulario, aceptas registrarte en HolaDOC y recibir información promocional personalizada a través del correo electrónico y número de teléfono proporcionados. Puedes solicitar la baja en cualquier momento</label>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>

    <div class="right-section-desktop w-6/12">
      
      <swiper-container 
        id="swiperContainerDesktop" 
        class="mySwiper" 
        space-between="1"
        slides-per-view="auto"
        navigation="true"
        speed="800"
        allowTouchMove="false"
        simulateTouch="false"
        touchRatio="0"
        noSwiping="false"
        (swiperslidenexttransitionstart)="onSlideChangeStart($event)"
        (swiperslideprevtransitionstart)="onSlideChangeEnd($event)"
        >
        
        <swiper-slide *ngFor="let cover of digitalAssets; index as i" class="rounded-lg" [attr.data-position]="i + 1">
          <img [src]="assetsUrl + '/' + cover.img" [alt]="cover.name" class="rounded-lg">
        </swiper-slide>
        <swiper-slide id="swiperSlideHelper" style="visibility: hidden; width: 40%;"></swiper-slide>

      </swiper-container>
      
      <div class="flex flex-row justify-end gap-8 mr-8 navigate-buttons" style="z-index: 1; position: relative;">
        <img class="manage-move-prev" width="50" src="assets/home/leftArrow.png" alt="left" id="leftArrow" (click)="navigateClick('swiper-button-prev', false, 1)" style="cursor: pointer;">
        <img class="manage-move-next" width="50" src="assets/home/rigthArrow.png" alt="right" id="rigthArrow" (click)="navigateClick('swiper-button-next', false, 1)" style="cursor: pointer;">
      </div>
    </div>
  </div>

</div>