<div id="mainContainer" class="h-screen overflow-hidden bg-left bg-no-repeat ">

  <div class="lg:w-full flex flex-col flex-wrap content-end mt-8" id="bodyContent">

    <div class="text-end mr-6 button-close">
      <button class="close" mat-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <!-- w-1/2 -->
    <div id="bodyContainer" class="sm:w-1/2 md:w-1/2 lg:w-1/2 flex flex-col items-center content-end font-bold text-3xl relative right-12">
      
      <p class="text-blue-600 text-center">
        {{ dataIncentive.text }} 
      </p>
  
      <p class="text-red-500 text-center">
        {{ dataIncentive.highlightedText }}
      </p>

      <div id="imgContainer" class="flex justify-center mt-20">

        <img [src]="assetsUrl + '/' + dataIncentive.img" alt="test">
      </div>
    </div>

    <!-- <div class="flex flex-row justify-end translate-y-3">
      <img src="assets/modal/Layer.png" alt="footer">
    </div> -->


    <!-- <div class="bg-right bg-no-repeat bg-contain bg-[url(assets/modal/Layer.png)]"> -->
      <!-- <p>H</p> -->
    <!-- </div> -->

  </div>

  <!-- <div class="flex flex-col flex-wrap content-end mt-8"></div> -->

</div>