import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

enum EmailStatus {
  success = 'true',
  failed = 'false'
}

@Component({
  selector: 'app-succes-failed-email',
  templateUrl: './succes-failed-email.component.html',
  styleUrls: ['./succes-failed-email.component.scss']
})
export class SuccesFailedEmailComponent implements OnInit {

  response:string = ''
  token = ''
  emailSuccess: boolean = false

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.response = params['status']
      this.token = params['token']
    });

    if ( !this.response || !this.token ) {
      this.router.navigate([''])
    }

    console.log(this.response);

    this.emailSuccess = (this.response == EmailStatus.success) ? true : false
  }

  goToAnotherResource() {
    this.router.navigate( ['/home'],{ queryParams: { 'reintent': true, success: true } } );
  }

  goToHomeFromError(){
    this.router.navigate(['']);
  }

  goToReintent() {
    this.router.navigate( ['/home'],{ queryParams: { 'reintent': true, success: false } } );
  }


}
