import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { register as registerSwiperElements } from 'swiper/element/bundle';
import clevertap from 'clevertap-web-sdk';
import { environment } from './app/environments/environment';

if ( environment.clevertapActive ) {
  clevertap.init(environment.clevertapId);
}


registerSwiperElements();

platformBrowserDynamic().bootstrapModule(AppModule, {
  ngZoneEventCoalescing: true
})
  .catch(err => console.error(err));
