import { Component } from '@angular/core';
import { CleverTapService } from './core/services/clevertap.service';
import version from "../../package.json"
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  constructor(private readonly clevertap: CleverTapService){
    console.log(`Versión ${version.version}`);
    this.clevertap.registerEvent$('visit_landing_page', {
    
    });
  }
  
  title = 'new-biblioteca-digital';
}
