import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { SuccesFailedEmailComponent } from './pages/succes-failed-email/succes-failed-email.component';

const routes: Routes = [
  {
    path:'',
    component: HomeComponent
},
{
    path: 'home',
    component: HomeComponent
},
{
    path: 'serviceEmail',
    component: SuccesFailedEmailComponent
},
{
    path: '**',
    component: HomeComponent
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
