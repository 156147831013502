import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import clevertap from 'clevertap-web-sdk';

@Injectable({
  providedIn: 'root',
})
export class CleverTapService {
  constructor(private readonly platform: Platform) {}
  registerEvent$(eventName: string, eventData: any) {

    console.log('registerEvent$', eventName, eventData);
    clevertap.event.push(eventName, {
      ...eventData,
      timestamp: new Date().toISOString(),
      device_type: this.platform.isBrowser ? 'web' : 'mobile',
    });
  }
}
