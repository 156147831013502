export type ShdCountry = {
    nombre: string;
    name: string;
    nom: string;
    iso2: string;
    iso3: string;
    phoneCode: string;
    svgIcon: string;
    newSvgIcon: string;
    id: string;
};

export const SUPPORTED_COUNTRIES: ShdCountry[] = [
  {
    nombre: 'Afganistán',
    name: 'Afghanistan',
    nom: 'Afghanistan',
    iso2: 'AF',
    iso3: 'AFG',
    phoneCode: '+93',
    svgIcon: 'flag-icon-af',
    newSvgIcon: 'af',
    id: 'e7918791-d7f2-4fe5-8d52-51974d9a0da0'
  },
  {
    nombre: 'Albania',
    name: 'Albania',
    nom: 'Albanie',
    iso2: 'AL',
    iso3: 'ALB',
    phoneCode: '+355',
    svgIcon: 'flag-icon-al',
    newSvgIcon: 'al',
    id: '4fb1e4c1-cbcd-4a48-b22d-154643645d01'
  },
  {
    nombre: 'Alemania',
    name: 'Germany',
    nom: 'Allemagne',
    iso2: 'DE',
    iso3: 'DEU',
    phoneCode: '+49',
    svgIcon: 'flag-icon-de',
    newSvgIcon: 'de',
    id: 'b551611c-1022-4885-8b88-3c21dfdba4b3'
  },
  {
    nombre: 'Algeria',
    name: 'Algeria',
    nom: 'Algérie',
    iso2: 'DZ',
    iso3: 'DZA',
    phoneCode: '+213',
    svgIcon: 'flag-icon-dz',
    newSvgIcon: 'dz',
    id: 'bd4a432e-21ad-4ee0-9b24-fa1475ecd96f'
  },
  {
    nombre: 'Andorra',
    name: 'Andorra',
    nom: 'Andorra',
    iso2: 'AD',
    iso3: 'AND',
    phoneCode: '+376',
    svgIcon: 'flag-icon-ad',
    newSvgIcon: 'ad',
    id: 'ee265a9d-32c9-47fe-a93f-17a886e9b361'
  },
  {
    nombre: 'Angola',
    name: 'Angola',
    nom: 'Angola',
    iso2: 'AO',
    iso3: 'AGO',
    phoneCode: '+244',
    svgIcon: 'flag-icon-ao',
    newSvgIcon: 'ao',
    id: '10310c18-164d-481a-8a3a-cc1b3a4cb469'
  },
  {
    nombre: 'Anguila',
    name: 'Anguilla',
    nom: 'Anguilla',
    iso2: 'AI',
    iso3: 'AIA',
    phoneCode: '+1 264',
    svgIcon: 'flag-icon-ai',
    newSvgIcon: 'ai',
    id: '831e68d7-48a9-42bc-a994-b89ad338b936'
  },
  {
    nombre: 'Antártida',
    name: 'Antarctica',
    nom: "L'Antarctique",
    iso2: 'AQ',
    iso3: 'ATA',
    phoneCode: '+672',
    svgIcon: 'flag-icon-aq',
    newSvgIcon: 'aq',
    id: 'c8299cce-86cf-47a9-8baa-b251e81bf998'
  },
  {
    nombre: 'Antigua y Barbuda',
    name: 'Antigua and Barbuda',
    nom: 'Antigua et Barbuda',
    iso2: 'AG',
    iso3: 'ATG',
    phoneCode: '+1 268',
    svgIcon: 'flag-icon-ag',
    newSvgIcon: 'ag',
    id: 'b158ac4d-a296-414a-9ccd-6cf3271e798e'
  },
  {
    nombre: 'Arabia Saudita',
    name: 'Saudi Arabia',
    nom: 'Arabie Saoudite',
    iso2: 'SA',
    iso3: 'SAU',
    phoneCode: '+966',
    svgIcon: 'flag-icon-sa',
    newSvgIcon: 'sa',
    id: 'bedeca6a-d076-4326-97be-267a1f365173'
  },
  {
    nombre: 'Argentina',
    name: 'Argentina',
    nom: 'Argentine',
    iso2: 'AR',
    iso3: 'ARG',
    phoneCode: '+54',
    svgIcon: 'flag-icon-ar',
    newSvgIcon: 'ar',
    id: '96be3005-416e-4871-975e-3d571fbb594a'
  },
  {
    nombre: 'Armenia',
    name: 'Armenia',
    nom: "L'Arménie",
    iso2: 'AM',
    iso3: 'ARM',
    phoneCode: '+374',
    svgIcon: 'flag-icon-am',
    newSvgIcon: 'am',
    id: '4b6da074-261a-435a-b1b1-eb54ad96dbe4'
  },
  {
    nombre: 'Aruba',
    name: 'Aruba',
    nom: 'Aruba',
    iso2: 'AW',
    iso3: 'ABW',
    phoneCode: '+297',
    svgIcon: 'flag-icon-aw',
    newSvgIcon: 'aw',
    id: '259f4c36-c029-4f6b-b552-6665f7482de5'
  },
  {
    nombre: 'Australia',
    name: 'Australia',
    nom: 'Australie',
    iso2: 'AU',
    iso3: 'AUS',
    phoneCode: '+61',
    svgIcon: 'flag-icon-au',
    newSvgIcon: 'au',
    id: '5444ca74-1fb6-46a0-8377-d52b6ac37a4b'
  },
  {
    nombre: 'Austria',
    name: 'Austria',
    nom: 'Autriche',
    iso2: 'AT',
    iso3: 'AUT',
    phoneCode: '+43',
    svgIcon: 'flag-icon-at',
    newSvgIcon: 'at',
    id: '2f6fc605-7d45-4b01-a98c-5feb84e509cb'
  },
  {
    nombre: 'Azerbayán',
    name: 'Azerbaijan',
    nom: "L'Azerbaïdjan",
    iso2: 'AZ',
    iso3: 'AZE',
    phoneCode: '+994',
    svgIcon: 'flag-icon-az',
    newSvgIcon: 'az',
    id: 'ed1dde5a-8d26-4170-9988-55ba17d48c7f'
  },
  {
    nombre: 'Bélgica',
    name: 'Belgium',
    nom: 'Belgique',
    iso2: 'BE',
    iso3: 'BEL',
    phoneCode: '+32',
    svgIcon: 'flag-icon-be',
    newSvgIcon: 'be',
    id: '966e4222-82f0-4d6d-ba69-6ff22c94a475'
  },
  {
    nombre: 'Bahamas',
    name: 'Bahamas',
    nom: 'Bahamas',
    iso2: 'BS',
    iso3: 'BHS',
    phoneCode: '+1 242',
    svgIcon: 'flag-icon-bs',
    newSvgIcon: 'bs',
    id: 'add77645-b7bc-4b78-86d2-32e6976a13a1'
  },
  {
    nombre: 'Bahrein',
    name: 'Bahrain',
    nom: 'Bahreïn',
    iso2: 'BH',
    iso3: 'BHR',
    phoneCode: '+973',
    svgIcon: 'flag-icon-bh',
    newSvgIcon: 'bh',
    id: '517280ec-e148-46e0-a513-b66ae82c7584'
  },
  {
    nombre: 'Bangladesh',
    name: 'Bangladesh',
    nom: 'Bangladesh',
    iso2: 'BD',
    iso3: 'BGD',
    phoneCode: '+880',
    svgIcon: 'flag-icon-bd',
    newSvgIcon: 'bd',
    id: '1b14d02c-8090-4776-a5f1-2c85ea40b99d'
  },
  {
    nombre: 'Barbados',
    name: 'Barbados',
    nom: 'Barbade',
    iso2: 'BB',
    iso3: 'BRB',
    phoneCode: '+1 246',
    svgIcon: 'flag-icon-bb',
    newSvgIcon: 'bb',
    id: 'a7360b0d-d1e5-471f-8648-724058607cbd'
  },
  {
    nombre: 'Belice',
    name: 'Belize',
    nom: 'Belize',
    iso2: 'BZ',
    iso3: 'BLZ',
    phoneCode: '+501',
    svgIcon: 'flag-icon-bz',
    newSvgIcon: 'bz',
    id: 'e613ab4c-9c2e-46b5-a512-823578afd3a7'
  },
  {
    nombre: 'Benín',
    name: 'Benin',
    nom: 'Bénin',
    iso2: 'BJ',
    iso3: 'BEN',
    phoneCode: '+229',
    svgIcon: 'flag-icon-bj',
    newSvgIcon: 'bj',
    id: 'd6ea658a-2af3-420b-8565-186850b3205d'
  },
  {
    nombre: 'Bhután',
    name: 'Bhutan',
    nom: 'Le Bhoutan',
    iso2: 'BT',
    iso3: 'BTN',
    phoneCode: '+975',
    svgIcon: 'flag-icon-bt',
    newSvgIcon: 'bt',
    id: '8e7111c2-94f2-4a6c-a48a-70d2966ede0d'
  },
  {
    nombre: 'Bielorrusia',
    name: 'Belarus',
    nom: 'Biélorussie',
    iso2: 'BY',
    iso3: 'BLR',
    phoneCode: '+375',
    svgIcon: 'flag-icon-by',
    newSvgIcon: 'by',
    id: 'c6281ce1-1bfe-4a83-a7bc-e609b200f12a'
  },
  {
    nombre: 'Birmania',
    name: 'Myanmar',
    nom: 'Myanmar',
    iso2: 'MM',
    iso3: 'MMR',
    phoneCode: '+95',
    svgIcon: 'flag-icon-mm',
    newSvgIcon: 'mm',
    id: '0daf6fc2-3e99-4a6e-96e4-3ba0be23c51d'
  },
  {
    nombre: 'Bolivia',
    name: 'Bolivia',
    nom: 'Bolivie',
    iso2: 'BO',
    iso3: 'BOL',
    phoneCode: '+591',
    svgIcon: 'flag-icon-bo',
    newSvgIcon: 'bo',
    id: 'b905e177-d065-4fe0-be7f-a609c0520f0a'
  },
  {
    nombre: 'Bosnia y Herzegovina',
    name: 'Bosnia and Herzegovina',
    nom: 'Bosnie-Herzégovine',
    iso2: 'BA',
    iso3: 'BIH',
    phoneCode: '+387',
    svgIcon: 'flag-icon-ba',
    newSvgIcon: 'ba',
    id: '4726fc61-0a27-43ef-b3f9-e2bb5280ae94'
  },
  {
    nombre: 'Botsuana',
    name: 'Botswana',
    nom: 'Botswana',
    iso2: 'BW',
    iso3: 'BWA',
    phoneCode: '+267',
    svgIcon: 'flag-icon-bw',
    newSvgIcon: 'bw',
    id: '4d370762-60f3-485c-874f-3ad615cd6228'
  },
  {
    nombre: 'Brasil',
    name: 'Brazil',
    nom: 'Brésil',
    iso2: 'BR',
    iso3: 'BRA',
    phoneCode: '+55',
    svgIcon: 'flag-icon-br',
    newSvgIcon: 'br',
    id: '7f6a5eeb-428c-4fc1-837a-1e89195927c7'
  },
  {
    nombre: 'Brunéi',
    name: 'Brunei',
    nom: 'Brunei',
    iso2: 'BN',
    iso3: 'BRN',
    phoneCode: '+673',
    svgIcon: 'flag-icon-bn',
    newSvgIcon: 'bn',
    id: '32f3875f-6f14-4fd2-93cc-d1a6d2a941b3'
  },
  {
    nombre: 'Bulgaria',
    name: 'Bulgaria',
    nom: 'Bulgarie',
    iso2: 'BG',
    iso3: 'BGR',
    phoneCode: '+359',
    svgIcon: 'flag-icon-bg',
    newSvgIcon: 'bg',
    id: '72b87cba-adc8-420b-9d4b-737ff19c139f'
  },
  {
    nombre: 'Burkina Faso',
    name: 'Burkina Faso',
    nom: 'Burkina Faso',
    iso2: 'BF',
    iso3: 'BFA',
    phoneCode: '+226',
    svgIcon: 'flag-icon-bf',
    newSvgIcon: 'bf',
    id: 'c8c5f9f6-d519-456c-89a9-b392d7f3cf2a'
  },
  {
    nombre: 'Burundi',
    name: 'Burundi',
    nom: 'Burundi',
    iso2: 'BI',
    iso3: 'BDI',
    phoneCode: '+257',
    svgIcon: 'flag-icon-bi',
    newSvgIcon: 'bi',
    id: '381500f3-efcd-43de-ae62-4d578a6a2a24'
  },
  {
    nombre: 'Cabo Verde',
    name: 'Cape Verde',
    nom: 'Cap-Vert',
    iso2: 'CV',
    iso3: 'CPV',
    phoneCode: '+238',
    svgIcon: 'flag-icon-cv',
    newSvgIcon: 'cv',
    id: 'f5714140-c54d-4c14-af52-73b01a251224'
  },
  {
    nombre: 'Camboya',
    name: 'Cambodia',
    nom: 'Cambodge',
    iso2: 'KH',
    iso3: 'KHM',
    phoneCode: '+855',
    svgIcon: 'flag-icon-kh',
    newSvgIcon: 'kh',
    id: '2a324322-5e7c-4616-8b4f-f0fe94fa67f3'
  },
  {
    nombre: 'Camerún',
    name: 'Cameroon',
    nom: 'Cameroun',
    iso2: 'CM',
    iso3: 'CMR',
    phoneCode: '+237',
    svgIcon: 'flag-icon-cm',
    newSvgIcon: 'cm',
    id: 'f04099c0-6598-4a54-a9dd-5ef92c8b4cc6'
  },
  {
    nombre: 'Canadá',
    name: 'Canada',
    nom: 'Canada',
    iso2: 'CA',
    iso3: 'CAN',
    phoneCode: '+1',
    svgIcon: 'flag-icon-ca',
    newSvgIcon: 'ca',
    id: '294baf3a-ba27-4fe1-9c61-528387807030'
  },
  {
    nombre: 'Chad',
    name: 'Chad',
    nom: 'Tchad',
    iso2: 'TD',
    iso3: 'TCD',
    phoneCode: '+235',
    svgIcon: 'flag-icon-td',
    newSvgIcon: 'td',
    id: 'b18d09aa-a64e-47c3-9d10-bcea678fbdc2'
  },
  {
    nombre: 'Chile',
    name: 'Chile',
    nom: 'Chili',
    iso2: 'CL',
    iso3: 'CHL',
    phoneCode: '+56',
    svgIcon: 'flag-icon-cl',
    newSvgIcon: 'cl',
    id: '83d7f69f-89df-4aea-ba41-1fb41fe27212'
  },
  {
    nombre: 'China',
    name: 'China',
    nom: 'Chine',
    iso2: 'CN',
    iso3: 'CHN',
    phoneCode: '+86',
    svgIcon: 'flag-icon-cn',
    newSvgIcon: 'cn',
    id: '5b892c58-fef0-4ee0-8d3c-134d78496e00'
  },
  {
    nombre: 'Chipre',
    name: 'Cyprus',
    nom: 'Chypre',
    iso2: 'CY',
    iso3: 'CYP',
    phoneCode: '+357',
    svgIcon: 'flag-icon-cy',
    newSvgIcon: 'cy',
    id: '5ee35882-bf5c-43ae-b09f-904997ff077c'
  },
  {
    nombre: 'Ciudad del Vaticano',
    name: 'Vatican City State',
    nom: 'Cité du Vatican',
    iso2: 'VA',
    iso3: 'VAT',
    phoneCode: '+39',
    svgIcon: 'flag-icon-va',
    newSvgIcon: 'va',
    id: 'fb571f9a-25dd-42b8-bfe3-9bab46e63b2d'
  },
  {
    nombre: 'Colombia',
    name: 'Colombia',
    nom: 'Colombie',
    iso2: 'CO',
    iso3: 'COL',
    phoneCode: '+57',
    svgIcon: 'flag-icon-co',
    newSvgIcon: 'co',
    id: 'aa7f8a40-ca8c-421c-b17d-3185aa9cf30c'
  },
  {
    nombre: 'Comoras',
    name: 'Comoros',
    nom: 'Comores',
    iso2: 'KM',
    iso3: 'COM',
    phoneCode: '+269',
    svgIcon: 'flag-icon-km',
    newSvgIcon: 'km',
    id: '96e6910a-dbd9-43ef-a3f5-87693704336e'
  },
  {
    nombre: 'Congo',
    name: 'Congo',
    nom: 'Congo',
    iso2: 'CG',
    iso3: 'COG',
    phoneCode: '+242',
    svgIcon: 'flag-icon-cg',
    newSvgIcon: 'cg',
    id: 'c093769e-f003-435a-b4c2-aba12283fd99'
  },
  {
    nombre: 'Congo',
    name: 'Congo',
    nom: 'Congo',
    iso2: 'CD',
    iso3: 'COD',
    phoneCode: '+243',
    svgIcon: 'flag-icon-cd',
    newSvgIcon: 'cd',
    id: '960510bb-16fa-44c3-9a91-70727aebc672'
  },
  {
    nombre: 'Corea del Norte',
    name: 'North Korea',
    nom: 'Corée du Nord',
    iso2: 'KP',
    iso3: 'PRK',
    phoneCode: '+850',
    svgIcon: 'flag-icon-kp',
    newSvgIcon: 'kp',
    id: 'b0e98978-a29f-4719-96c8-7279846a8bc6'
  },
  {
    nombre: 'Corea del Sur',
    name: 'South Korea',
    nom: 'Corée du Sud',
    iso2: 'KR',
    iso3: 'KOR',
    phoneCode: '+82',
    svgIcon: 'flag-icon-kr',
    newSvgIcon: 'kr',
    id: '6981e1a7-0683-43b4-9e14-83a6bce33519'
  },
  {
    nombre: 'Costa de Marfil',
    name: 'Ivory Coast',
    nom: "Côte-d'Ivoire",
    iso2: 'CI',
    iso3: 'CIV',
    phoneCode: '+225',
    svgIcon: 'flag-icon-ci',
    newSvgIcon: 'ci',
    id: '0896a0fc-a2c9-410c-a8ac-e169866787bb'
  },
  {
    nombre: 'Costa Rica',
    name: 'Costa Rica',
    nom: 'Costa Rica',
    iso2: 'CR',
    iso3: 'CRI',
    phoneCode: '+506',
    svgIcon: 'flag-icon-af',
    newSvgIcon: 'af',
    id: 'acd9c0f1-1b61-4236-9bb8-c58863a9b192'
  },
  {
    nombre: 'Croacia',
    name: 'Croatia',
    nom: 'Croatie',
    iso2: 'HR',
    iso3: 'HRV',
    phoneCode: '+385',
    svgIcon: 'flag-icon-hr',
    newSvgIcon: 'hr',
    id: '115b5ca8-44a1-4441-be83-0fc95ae3e7f8'
  },
  {
    nombre: 'Cuba',
    name: 'Cuba',
    nom: 'Cuba',
    iso2: 'CU',
    iso3: 'CUB',
    phoneCode: '+53',
    svgIcon: 'flag-icon-cu',
    newSvgIcon: 'cu',
    id: 'd2a17469-4370-43f5-bd4e-b98c0bb8aa5c'
  },
  {
    nombre: 'Dinamarca',
    name: 'Denmark',
    nom: 'Danemark',
    iso2: 'DK',
    iso3: 'DNK',
    phoneCode: '+45',
    svgIcon: 'flag-icon-dk',
    newSvgIcon: 'dk',
    id: '6276f514-fbf0-43fd-9aa4-5c5350518ac3'
  },
  {
    nombre: 'Dominica',
    name: 'Dominica',
    nom: 'Dominique',
    iso2: 'DM',
    iso3: 'DMA',
    phoneCode: '+1 767',
    svgIcon: 'flag-icon-dm',
    newSvgIcon: 'dm',
    id: 'a55f5943-0e77-40d7-9683-ef04a804b2b0'
  },
  {
    nombre: 'Ecuador',
    name: 'Ecuador',
    nom: 'Equateur',
    iso2: 'EC',
    iso3: 'ECU',
    phoneCode: '+593',
    svgIcon: 'flag-icon-ec',
    newSvgIcon: 'ec',
    id: 'ebe73240-c737-48da-ae0e-66d4e21ead15'
  },
  {
    nombre: 'Egipto',
    name: 'Egypt',
    nom: 'Egypte',
    iso2: 'EG',
    iso3: 'EGY',
    phoneCode: '+20',
    svgIcon: 'flag-icon-eg',
    newSvgIcon: 'eg',
    id: '93eeb0e0-2103-4e53-b395-48a569258db9'
  },
  {
    nombre: 'El Salvador',
    name: 'El Salvador',
    nom: 'El Salvador',
    iso2: 'SV',
    iso3: 'SLV',
    phoneCode: '+503',
    svgIcon: 'flag-icon-sv',
    newSvgIcon: 'sv',
    id: '8bb2c5a4-c567-4284-a9e6-02e1d5890aa9'
  },
  {
    nombre: 'Emiratos Árabes Unidos',
    name: 'United Arab Emirates',
    nom: 'Emirats Arabes Unis',
    iso2: 'AE',
    iso3: 'ARE',
    phoneCode: '+971',
    svgIcon: 'flag-icon-ae',
    newSvgIcon: 'ae',
    id: '6eef97eb-63cf-43cc-b0ad-2fe9eb2cc5fa'
  },
  {
    nombre: 'Eritrea',
    name: 'Eritrea',
    nom: 'Erythrée',
    iso2: 'ER',
    iso3: 'ERI',
    phoneCode: '+291',
    svgIcon: 'flag-icon-er',
    newSvgIcon: 'er',
    id: 'b3330093-1fda-4a27-b214-f027897a91f5'
  },
  {
    nombre: 'Eslovaquia',
    name: 'Slovakia',
    nom: 'Slovaquie',
    iso2: 'SK',
    iso3: 'SVK',
    phoneCode: '+421',
    svgIcon: 'flag-icon-sk',
    newSvgIcon: 'sk',
    id: '99832c8c-3dcd-4777-b67b-b165b2568b30'
  },
  {
    nombre: 'Eslovenia',
    name: 'Slovenia',
    nom: 'Slovénie',
    iso2: 'SI',
    iso3: 'SVN',
    phoneCode: '+386',
    svgIcon: 'flag-icon-si',
    newSvgIcon: 'si',
    id: '18983584-1ff7-42d1-9071-2b3461fc4a36'
  },
  {
    nombre: 'España',
    name: 'Spain',
    nom: 'Espagne',
    iso2: 'ES',
    iso3: 'ESP',
    phoneCode: '+34',
    svgIcon: 'flag-icon-es',
    newSvgIcon: 'es',
    id: '20d582bb-be6e-427f-8118-71a64ac6379b'
  },
  {
    nombre: 'Estados Unidos de América',
    name: 'United States of America',
    nom: "États-Unis d'Amérique",
    iso2: 'US',
    iso3: 'USA',
    phoneCode: '+1',
    svgIcon: 'flag-icon-us',
    newSvgIcon: 'us',
    id: '871df945-f50f-41ba-9dc4-46ed2e2f07d0'
  },
  {
    nombre: 'Estonia',
    name: 'Estonia',
    nom: "L'Estonie",
    iso2: 'EE',
    iso3: 'EST',
    phoneCode: '+372',
    svgIcon: 'flag-icon-ee',
    newSvgIcon: 'ee',
    id: '96fa84bc-f87b-4abe-a8dc-33b2247145b2'
  },
  {
    nombre: 'Etiopía',
    name: 'Ethiopia',
    nom: 'Ethiopie',
    iso2: 'ET',
    iso3: 'ETH',
    phoneCode: '+251',
    svgIcon: 'flag-icon-et',
    newSvgIcon: 'et',
    id: '24dc720f-9969-4c02-a805-6cd8804910f6'
  },
  {
    nombre: 'Filipinas',
    name: 'Philippines',
    nom: 'Philippines',
    iso2: 'PH',
    iso3: 'PHL',
    phoneCode: '+63',
    svgIcon: 'flag-icon-ph',
    newSvgIcon: 'ph',
    id: '68c25d19-c8cd-4952-9e1b-89123896bfeb'
  },
  {
    nombre: 'Finlandia',
    name: 'Finland',
    nom: 'Finlande',
    iso2: 'FI',
    iso3: 'FIN',
    phoneCode: '+358',
    svgIcon: 'flag-icon-fi',
    newSvgIcon: 'fi',
    id: 'd5863bc4-da6d-4396-9cb9-a83698c1dc62'
  },
  {
    nombre: 'Fiyi',
    name: 'Fiji',
    nom: 'Fidji',
    iso2: 'FJ',
    iso3: 'FJI',
    phoneCode: '+679',
    svgIcon: 'flag-icon-fj',
    newSvgIcon: 'fj',
    id: 'f0c251e1-c8e2-4f66-9195-0833801d9fd8'
  },
  {
    nombre: 'Francia',
    name: 'France',
    nom: 'France',
    iso2: 'FR',
    iso3: 'FRA',
    phoneCode: '+33',
    svgIcon: 'flag-icon-fr',
    newSvgIcon: 'fr',
    id: '782efb9a-b1cf-4be3-95c3-235227dd42d4'
  },
  {
    nombre: 'Gabón',
    name: 'Gabon',
    nom: 'Gabon',
    iso2: 'GA',
    iso3: 'GAB',
    phoneCode: '+241',
    svgIcon: 'flag-icon-ga',
    newSvgIcon: 'ga',
    id: '004b96cd-40cb-4497-9fce-a16da0a78a7e'
  },
  {
    nombre: 'Gambia',
    name: 'Gambia',
    nom: 'Gambie',
    iso2: 'GM',
    iso3: 'GMB',
    phoneCode: '+220',
    svgIcon: 'flag-icon-gm',
    newSvgIcon: 'gm',
    id: '1d44c280-637e-4517-86a6-9ce3b1a37a44'
  },
  {
    nombre: 'Georgia',
    name: 'Georgia',
    nom: 'Géorgie',
    iso2: 'GE',
    iso3: 'GEO',
    phoneCode: '+995',
    svgIcon: 'flag-icon-ge',
    newSvgIcon: 'ge',
    id: 'e23eccce-e4d5-4e96-a18f-bbddf00337d1'
  },
  {
    nombre: 'Ghana',
    name: 'Ghana',
    nom: 'Ghana',
    iso2: 'GH',
    iso3: 'GHA',
    phoneCode: '+233',
    svgIcon: 'flag-icon-gh',
    newSvgIcon: 'gh',
    id: '94ad44d2-bade-47f6-be54-21e3c369c901'
  },
  {
    nombre: 'Gibraltar',
    name: 'Gibraltar',
    nom: 'Gibraltar',
    iso2: 'GI',
    iso3: 'GIB',
    phoneCode: '+350',
    svgIcon: 'flag-icon-gi',
    newSvgIcon: 'gi',
    id: 'b864cfa0-8e85-4771-a2a7-42cde182b2b9'
  },
  {
    nombre: 'Granada',
    name: 'Grenada',
    nom: 'Grenade',
    iso2: 'GD',
    iso3: 'GRD',
    phoneCode: '+1 473',
    svgIcon: 'flag-icon-gd',
    newSvgIcon: 'gd',
    id: 'cd64855a-f7f8-48da-af8b-33c2e890096a'
  },
  {
    nombre: 'Grecia',
    name: 'Greece',
    nom: 'Grèce',
    iso2: 'GR',
    iso3: 'GRC',
    phoneCode: '+30',
    svgIcon: 'flag-icon-gr',
    newSvgIcon: 'gr',
    id: 'dce0adb5-4066-4e6c-94b2-ad4046fed5ce'
  },
  {
    nombre: 'Groenlandia',
    name: 'Greenland',
    nom: 'Groenland',
    iso2: 'GL',
    iso3: 'GRL',
    phoneCode: '+299',
    svgIcon: 'flag-icon-gl',
    newSvgIcon: 'gl',
    id: '231aa899-3bdf-4a95-88f1-4fb7a83a7352'
  },
  {
    nombre: 'Guadalupe',
    name: 'Guadeloupe',
    nom: 'Guadeloupe',
    iso2: 'GP',
    iso3: 'GLP',
    phoneCode: '+',
    svgIcon: 'flag-icon-gp',
    newSvgIcon: 'gp',
    id: '61b0abec-f04e-4200-9be4-fac5fee6a18b'
  },
  {
    nombre: 'Guam',
    name: 'Guam',
    nom: 'Guam',
    iso2: 'GU',
    iso3: 'GUM',
    phoneCode: '+1 671',
    svgIcon: 'flag-icon-gu',
    newSvgIcon: 'gu',
    id: '06031464-c61c-4e6e-8c62-27f1131891ba'
  },
  {
    nombre: 'Guatemala',
    name: 'Guatemala',
    nom: 'Guatemala',
    iso2: 'GT',
    iso3: 'GTM',
    phoneCode: '+502',
    svgIcon: 'flag-icon-gt',
    newSvgIcon: 'gt',
    id: '9cd17001-8993-4240-927d-dc4eefeee9ca'
  },
  {
    nombre: 'Guayana Francesa',
    name: 'French Guiana',
    nom: 'Guyane française',
    iso2: 'GF',
    iso3: 'GUF',
    phoneCode: '+',
    svgIcon: 'flag-icon-gf',
    newSvgIcon: 'gf',
    id: '6e8fea41-8d95-4394-b46c-f5f76bbc45ef'
  },
  {
    nombre: 'Guernsey',
    name: 'Guernsey',
    nom: 'Guernesey',
    iso2: 'GG',
    iso3: 'GGY',
    phoneCode: '+',
    svgIcon: 'flag-icon-gg',
    newSvgIcon: 'gg',
    id: '8cb0f32c-d7e8-4164-8f4f-2c7afd9f80b2'
  },
  {
    nombre: 'Guinea',
    name: 'Guinea',
    nom: 'Guinée',
    iso2: 'GN',
    iso3: 'GIN',
    phoneCode: '+224',
    svgIcon: 'flag-icon-gn',
    newSvgIcon: 'gn',
    id: '9594c5a1-79f2-462c-8462-066cb89db3ae'
  },
  {
    nombre: 'Guinea Ecuatorial',
    name: 'Equatorial Guinea',
    nom: 'Guinée Equatoriale',
    iso2: 'GQ',
    iso3: 'GNQ',
    phoneCode: '+240',
    svgIcon: 'flag-icon-gq',
    newSvgIcon: 'gq',
    id: 'b583e624-2e53-4bf7-b7f1-aaf17cfdcab2'
  },
  {
    nombre: 'Guinea-Bissau',
    name: 'Guinea-Bissau',
    nom: 'Guinée-Bissau',
    iso2: 'GW',
    iso3: 'GNB',
    phoneCode: '+245',
    svgIcon: 'flag-icon-gw',
    newSvgIcon: 'gw',
    id: '52a4b02b-1d5a-4936-9df5-be1b0664fbe0'
  },
  {
    nombre: 'Guyana',
    name: 'Guyana',
    nom: 'Guyane',
    iso2: 'GY',
    iso3: 'GUY',
    phoneCode: '+592',
    svgIcon: 'flag-icon-gy',
    newSvgIcon: 'gy',
    id: 'fbfa083d-4f51-4d78-bbee-3719d6a8b2d9'
  },
  {
    nombre: 'Haití',
    name: 'Haiti',
    nom: 'Haïti',
    iso2: 'HT',
    iso3: 'HTI',
    phoneCode: '+509',
    svgIcon: 'flag-icon-ht',
    newSvgIcon: 'ht',
    id: '54b1a76e-c78b-46fb-a4da-4c0ceb624eca'
  },
  {
    nombre: 'Honduras',
    name: 'Honduras',
    nom: 'Honduras',
    iso2: 'HN',
    iso3: 'HND',
    phoneCode: '+504',
    svgIcon: 'flag-icon-hn',
    newSvgIcon: 'hn',
    id: '2cd49eda-0959-4f93-92e4-3a3e0fa8db52'
  },
  {
    nombre: 'Hong kong',
    name: 'Hong Kong',
    nom: 'Hong Kong',
    iso2: 'HK',
    iso3: 'HKG',
    phoneCode: '+852',
    svgIcon: 'flag-icon-hk',
    newSvgIcon: 'hk',
    id: '5fd595ac-075a-42a4-a59c-17ca7af93b6c'
  },
  {
    nombre: 'Hungría',
    name: 'Hungary',
    nom: 'Hongrie',
    iso2: 'HU',
    iso3: 'HUN',
    phoneCode: '+36',
    svgIcon: 'flag-icon-hu',
    newSvgIcon: 'hu',
    id: '0b43626e-4921-4f7b-80d3-4c75d4bc34c9'
  },
  {
    nombre: 'India',
    name: 'India',
    nom: 'Inde',
    iso2: 'IN',
    iso3: 'IND',
    phoneCode: '+91',
    svgIcon: 'flag-icon-in',
    newSvgIcon: 'in',
    id: '8045ca86-d54f-4efc-b293-5aa3d4c3bb5b'
  },
  {
    nombre: 'Indonesia',
    name: 'Indonesia',
    nom: 'Indonésie',
    iso2: 'ID',
    iso3: 'IDN',
    phoneCode: '+62',
    svgIcon: 'flag-icon-id',
    newSvgIcon: 'id',
    id: '7f3f9179-c057-48bb-a165-3e5454a3a420'
  },
  {
    nombre: 'Irán',
    name: 'Iran',
    nom: 'Iran',
    iso2: 'IR',
    iso3: 'IRN',
    phoneCode: '+98',
    svgIcon: 'flag-icon-ir',
    newSvgIcon: 'ir',
    id: '6407db3c-4a8b-4ade-a0da-dd1a8a606d06'
  },
  {
    nombre: 'Irak',
    name: 'Iraq',
    nom: 'Irak',
    iso2: 'IQ',
    iso3: 'IRQ',
    phoneCode: '+964',
    svgIcon: 'flag-icon-iq',
    newSvgIcon: 'iq',
    id: '6bd59fce-4de6-4bfe-8f36-cad26682360f'
  },
  {
    nombre: 'Irlanda',
    name: 'Ireland',
    nom: 'Irlande',
    iso2: 'IE',
    iso3: 'IRL',
    phoneCode: '+353',
    svgIcon: 'flag-icon-ie',
    newSvgIcon: 'ie',
    id: '1877ddda-2b97-48a0-968c-6a115207f3e5'
  },
  {
    nombre: 'Isla Bouvet',
    name: 'Bouvet Island',
    nom: 'Bouvet Island',
    iso2: 'BV',
    iso3: 'BVT',
    phoneCode: '+',
    svgIcon: 'flag-icon-bv',
    newSvgIcon: 'bv',
    id: 'af34302b-dd63-44c3-9e9d-e77fbffa17e4'
  },
  {
    nombre: 'Isla de Man',
    name: 'Isle of Man',
    nom: 'Ile de Man',
    iso2: 'IM',
    iso3: 'IMN',
    phoneCode: '+44',
    svgIcon: 'flag-icon-im',
    newSvgIcon: 'im',
    id: '2cc97a22-0fda-445a-b30a-b73bdd32af79'
  },
  {
    nombre: 'Isla de Navidad',
    name: 'Christmas Island',
    nom: 'Christmas Island',
    iso2: 'CX',
    iso3: 'CXR',
    phoneCode: '+61',
    svgIcon: 'flag-icon-cx',
    newSvgIcon: 'cx',
    id: '4da48001-beb5-4ada-9476-6aacb8114b3d'
  },
  {
    nombre: 'Isla Norfolk',
    name: 'Norfolk Island',
    nom: 'Île de Norfolk',
    iso2: 'NF',
    iso3: 'NFK',
    phoneCode: '+',
    svgIcon: 'flag-icon-nf',
    newSvgIcon: 'nf',
    id: '046978c7-a888-47b4-95e9-2a9344d3fcc3'
  },
  {
    nombre: 'Islandia',
    name: 'Iceland',
    nom: 'Islande',
    iso2: 'IS',
    iso3: 'ISL',
    phoneCode: '+354',
    svgIcon: 'flag-icon-is',
    newSvgIcon: 'is',
    id: 'c1370018-1f5c-4801-a859-360907078b1b'
  },
  {
    nombre: 'Islas Bermudas',
    name: 'Bermuda Islands',
    nom: 'Bermudes',
    iso2: 'BM',
    iso3: 'BMU',
    phoneCode: '+1 441',
    svgIcon: 'flag-icon-bm',
    newSvgIcon: 'bm',
    id: '125e6d2d-9122-42e9-af5b-3dbb53feea02'
  },
  {
    nombre: 'Islas Caimán',
    name: 'Cayman Islands',
    nom: 'Iles Caïmans',
    iso2: 'KY',
    iso3: 'CYM',
    phoneCode: '+1 345',
    svgIcon: 'flag-icon-ky',
    newSvgIcon: 'ky',
    id: 'f582d004-887c-47b4-8134-0a6badaf377b'
  },
  {
    nombre: 'Islas Cocos (Keeling)',
    name: 'Cocos (Keeling) Islands',
    nom: 'Cocos (Keeling',
    iso2: 'CC',
    iso3: 'CCK',
    phoneCode: '+61',
    svgIcon: 'flag-icon-cc',
    newSvgIcon: 'cc',
    id: '30b74571-0fc2-4696-8498-38132fa4099c'
  },
  {
    nombre: 'Islas Cook',
    name: 'Cook Islands',
    nom: 'Iles Cook',
    iso2: 'CK',
    iso3: 'COK',
    phoneCode: '+682',
    svgIcon: 'flag-icon-ck',
    newSvgIcon: 'ck',
    id: '57b22d9d-0c3d-4733-a274-cdb34343f271'
  },
  {
    nombre: 'Islas de Åland',
    name: 'Åland Islands',
    nom: 'Îles Åland',
    iso2: 'AX',
    iso3: 'ALA',
    phoneCode: '+',
    svgIcon: 'flag-icon-ax',
    newSvgIcon: 'ax',
    id: 'f68e7790-7ef6-4c8f-ad44-142ad5dc9d27'
  },
  {
    nombre: 'Islas Feroe',
    name: 'Faroe Islands',
    nom: 'Iles Féro',
    iso2: 'FO',
    iso3: 'FRO',
    phoneCode: '+298',
    svgIcon: 'flag-icon-fo',
    newSvgIcon: 'fo',
    id: 'a17358d5-1fa3-4a5f-8f26-e896caf3ca46'
  },
  {
    nombre: 'Islas Georgias del Sur y Sandwich del Sur',
    name: 'South Georgia and the South Sandwich Islands',
    nom: 'Géorgie du Sud et les Îles Sandwich du Sud',
    iso2: 'GS',
    iso3: 'SGS',
    phoneCode: '+',
    svgIcon: 'flag-icon-gs',
    newSvgIcon: 'gs',
    id: 'ee4e46be-fa21-4356-a84d-49be7963e948'
  },
  {
    nombre: 'Islas Heard y McDonald',
    name: 'Heard Island and McDonald Islands',
    nom: 'Les îles Heard et McDonald',
    iso2: 'HM',
    iso3: 'HMD',
    phoneCode: '+',
    svgIcon: 'flag-icon-hm',
    newSvgIcon: 'hm',
    id: '90968d26-59df-4f4f-bf05-85991d9bd2e3'
  },
  {
    nombre: 'Islas Maldivas',
    name: 'Maldives',
    nom: 'Maldives',
    iso2: 'MV',
    iso3: 'MDV',
    phoneCode: '+960',
    svgIcon: 'flag-icon-mv',
    newSvgIcon: 'mv',
    id: '84332384-c9bd-401a-928c-73b26102e171'
  },
  {
    nombre: 'Islas Malvinas',
    name: 'Falkland Islands (Malvinas)',
    nom: 'Iles Falkland (Malvinas',
    iso2: 'FK',
    iso3: 'FLK',
    phoneCode: '+500',
    svgIcon: 'flag-icon-fk',
    newSvgIcon: 'fk',
    id: '84f5d281-2cf1-4122-b21f-6ab37199f662'
  },
  {
    nombre: 'Islas Marianas del Norte',
    name: 'Northern Mariana Islands',
    nom: 'Iles Mariannes du Nord',
    iso2: 'MP',
    iso3: 'MNP',
    phoneCode: '+1 670',
    svgIcon: 'flag-icon-mp',
    newSvgIcon: 'mp',
    id: 'b3dce984-0016-416b-af46-034a2808e3c3'
  },
  {
    nombre: 'Islas Marshall',
    name: 'Marshall Islands',
    nom: 'Iles Marshall',
    iso2: 'MH',
    iso3: 'MHL',
    phoneCode: '+692',
    svgIcon: 'flag-icon-mh',
    newSvgIcon: 'mh',
    id: 'a1dd333f-f82a-468a-a3ec-eb92eadfea1b'
  },
  {
    nombre: 'Islas Pitcairn',
    name: 'Pitcairn Islands',
    nom: 'Iles Pitcairn',
    iso2: 'PN',
    iso3: 'PCN',
    phoneCode: '+870',
    svgIcon: 'flag-icon-pn',
    newSvgIcon: 'pn',
    id: '57291d9a-31a3-48cf-b531-a9599904c064'
  },
  {
    nombre: 'Islas Salomón',
    name: 'Solomon Islands',
    nom: 'Iles Salomon',
    iso2: 'SB',
    iso3: 'SLB',
    phoneCode: '+677',
    svgIcon: 'flag-icon-sb',
    newSvgIcon: 'sb',
    id: '4ed1d84c-4d02-47a2-92b4-a3d977d16513'
  },
  {
    nombre: 'Islas Turcas y Caicos',
    name: 'Turks and Caicos Islands',
    nom: 'Iles Turques et Caïques',
    iso2: 'TC',
    iso3: 'TCA',
    phoneCode: '+1 649',
    svgIcon: 'flag-icon-tc',
    newSvgIcon: 'tc',
    id: 'be6800c6-731c-4290-af65-256430b66141'
  },
  {
    nombre: 'Islas Ultramarinas Menores de Estados Unidos',
    name: 'United States Minor Outlying Islands',
    nom: 'États-Unis Îles mineures éloignées',
    iso2: 'UM',
    iso3: 'UMI',
    phoneCode: '+',
    svgIcon: 'flag-icon-um',
    newSvgIcon: 'um',
    id: '0123b0e3-b247-4742-9511-fd3524736b49'
  },
  {
    nombre: 'Islas Vírgenes Británicas',
    name: 'Virgin Islands',
    nom: 'Iles Vierges',
    iso2: 'VG',
    iso3: 'VG',
    phoneCode: '+1 284',
    svgIcon: 'flag-icon-vg',
    newSvgIcon: 'vg',
    id: 'eb740d5d-f503-4d8a-a2e8-c0559aeaeab2'
  },
  {
    nombre: 'Islas Vírgenes de los Estados Unidos',
    name: 'United States Virgin Islands',
    nom: 'Îles Vierges américaines',
    iso2: 'VI',
    iso3: 'VIR',
    phoneCode: '+1 340',
    svgIcon: 'flag-icon-vi',
    newSvgIcon: 'vi',
    id: 'd5270018-c554-4759-91c6-31995fc29aee'
  },
  {
    nombre: 'Israel',
    name: 'Israel',
    nom: 'Israël',
    iso2: 'IL',
    iso3: 'ISR',
    phoneCode: '+972',
    svgIcon: 'flag-icon-il',
    newSvgIcon: 'il',
    id: 'b4074a3f-68eb-4570-be42-c70eb4392ed1'
  },
  {
    nombre: 'Italia',
    name: 'Italy',
    nom: 'Italie',
    iso2: 'IT',
    iso3: 'ITA',
    phoneCode: '+39',
    svgIcon: 'flag-icon-it',
    newSvgIcon: 'it',
    id: '43c6513e-bc71-4e9e-895e-4b9c201bb754'
  },
  {
    nombre: 'Jamaica',
    name: 'Jamaica',
    nom: 'Jamaïque',
    iso2: 'JM',
    iso3: 'JAM',
    phoneCode: '+1 876',
    svgIcon: 'flag-icon-jm',
    newSvgIcon: 'jm',
    id: '411f4c49-ca31-44ef-a9fc-0aedb365faf2'
  },
  {
    nombre: 'Japón',
    name: 'Japan',
    nom: 'Japon',
    iso2: 'JP',
    iso3: 'JPN',
    phoneCode: '+81',
    svgIcon: 'flag-icon-jp',
    newSvgIcon: 'jp',
    id: 'a1599f75-0525-4ab3-ba92-3de991fe276b'
  },
  {
    nombre: 'Jersey',
    name: 'Jersey',
    nom: 'Maillot',
    iso2: 'JE',
    iso3: 'JEY',
    phoneCode: '+',
    svgIcon: 'flag-icon-je',
    newSvgIcon: 'je',
    id: '2d2f2000-44ba-4158-b988-3baed646c1a1'
  },
  {
    nombre: 'Jordania',
    name: 'Jordan',
    nom: 'Jordan',
    iso2: 'JO',
    iso3: 'JOR',
    phoneCode: '+962',
    svgIcon: 'flag-icon-jo',
    newSvgIcon: 'jo',
    id: 'e2ce1e8f-4060-47d5-a9e1-5003c1306e1c'
  },
  {
    nombre: 'Kazajistán',
    name: 'Kazakhstan',
    nom: 'Le Kazakhstan',
    iso2: 'KZ',
    iso3: 'KAZ',
    phoneCode: '+7',
    svgIcon: 'flag-icon-kz',
    newSvgIcon: 'kz',
    id: '88fea982-18aa-44f4-8019-ffded709fd18'
  },
  {
    nombre: 'Kenia',
    name: 'Kenya',
    nom: 'Kenya',
    iso2: 'KE',
    iso3: 'KEN',
    phoneCode: '+254',
    svgIcon: 'flag-icon-ke',
    newSvgIcon: 'ke',
    id: '13e4b64b-bc58-46a1-8b9b-2ecaf3042712'
  },
  {
    nombre: 'Kirgizstán',
    name: 'Kyrgyzstan',
    nom: 'Kirghizstan',
    iso2: 'KG',
    iso3: 'KGZ',
    phoneCode: '+996',
    svgIcon: 'flag-icon-kg',
    newSvgIcon: 'kg',
    id: 'd2df76ca-3b6a-46c6-8e06-0545df2896ca'
  },
  {
    nombre: 'Kiribati',
    name: 'Kiribati',
    nom: 'Kiribati',
    iso2: 'KI',
    iso3: 'KIR',
    phoneCode: '+686',
    svgIcon: 'flag-icon-ki',
    newSvgIcon: 'ki',
    id: 'bf21a5d0-7860-45bd-aec1-45c10ac5488c'
  },
  {
    nombre: 'Kuwait',
    name: 'Kuwait',
    nom: 'Koweït',
    iso2: 'KW',
    iso3: 'KWT',
    phoneCode: '+965',
    svgIcon: 'flag-icon-kw',
    newSvgIcon: 'kw',
    id: 'c1414daa-73bd-4057-bd3c-87a04aa9d1fa'
  },
  {
    nombre: 'Líbano',
    name: 'Lebanon',
    nom: 'Liban',
    iso2: 'LB',
    iso3: 'LBN',
    phoneCode: '+961',
    svgIcon: 'flag-icon-lb',
    newSvgIcon: 'lb',
    id: '8049fde8-7320-4360-9d17-0fae8bbd31da'
  },
  {
    nombre: 'Laos',
    name: 'Laos',
    nom: 'Laos',
    iso2: 'LA',
    iso3: 'LAO',
    phoneCode: '+856',
    svgIcon: 'flag-icon-la',
    newSvgIcon: 'la',
    id: '0bf723b4-00be-4aa7-84ce-9c6a6f30091c'
  },
  {
    nombre: 'Lesoto',
    name: 'Lesotho',
    nom: 'Lesotho',
    iso2: 'LS',
    iso3: 'LSO',
    phoneCode: '+266',
    svgIcon: 'flag-icon-ls',
    newSvgIcon: 'ls',
    id: 'e4e53bbe-d579-4c16-a6c7-4623a7f83152'
  },
  {
    nombre: 'Letonia',
    name: 'Latvia',
    nom: 'La Lettonie',
    iso2: 'LV',
    iso3: 'LVA',
    phoneCode: '+371',
    svgIcon: 'flag-icon-lv',
    newSvgIcon: 'lv',
    id: '1e2d589f-586d-442c-af87-ad0b2b1f9b92'
  },
  {
    nombre: 'Liberia',
    name: 'Liberia',
    nom: 'Liberia',
    iso2: 'LR',
    iso3: 'LBR',
    phoneCode: '+231',
    svgIcon: 'flag-icon-lr',
    newSvgIcon: 'lr',
    id: '46a95463-eb0e-4f34-bfd2-4ea175a1728e'
  },
  {
    nombre: 'Libia',
    name: 'Libya',
    nom: 'Libye',
    iso2: 'LY',
    iso3: 'LBY',
    phoneCode: '+218',
    svgIcon: 'flag-icon-ly',
    newSvgIcon: 'ly',
    id: '871a71c4-68da-4831-8ee2-ed4abc58defa'
  },
  {
    nombre: 'Liechtenstein',
    name: 'Liechtenstein',
    nom: 'Liechtenstein',
    iso2: 'LI',
    iso3: 'LIE',
    phoneCode: '+423',
    svgIcon: 'flag-icon-li',
    newSvgIcon: 'li',
    id: '0492b392-f607-43ab-880e-954d6cd42398'
  },
  {
    nombre: 'Lituania',
    name: 'Lithuania',
    nom: 'La Lituanie',
    iso2: 'LT',
    iso3: 'LTU',
    phoneCode: '+370',
    svgIcon: 'flag-icon-lt',
    newSvgIcon: 'lt',
    id: '3253d9e1-4a2c-4f7e-a8a7-89f3ed5898e2'
  },
  {
    nombre: 'Luxemburgo',
    name: 'Luxembourg',
    nom: 'Luxembourg',
    iso2: 'LU',
    iso3: 'LUX',
    phoneCode: '+352',
    svgIcon: 'flag-icon-lu',
    newSvgIcon: 'lu',
    id: 'ac39f922-f46e-4160-91d8-d7821976e320'
  },
  {
    nombre: 'México',
    name: 'Mexico',
    nom: 'Mexique',
    iso2: 'MX',
    iso3: 'MEX',
    phoneCode: '+52',
    svgIcon: 'flag-icon-mx',
    newSvgIcon: 'mx',
    id: '8ce3952a-3828-4a54-a023-f06c5c55868f'
  },
  {
    nombre: 'Mónaco',
    name: 'Monaco',
    nom: 'Monaco',
    iso2: 'MC',
    iso3: 'MCO',
    phoneCode: '+377',
    svgIcon: 'flag-icon-mc',
    newSvgIcon: 'mc',
    id: 'c5a6b98b-9cb4-410b-9bc0-da32226f71a9'
  },
  {
    nombre: 'Macao',
    name: 'Macao',
    nom: 'Macao',
    iso2: 'MO',
    iso3: 'MAC',
    phoneCode: '+853',
    svgIcon: 'flag-icon-mo',
    newSvgIcon: 'mo',
    id: '4bd81a8a-0cb2-40f6-840d-b40269b5cb36'
  },
  {
    nombre: 'Macedônia',
    name: 'Macedonia',
    nom: 'Macédoine',
    iso2: 'MK',
    iso3: 'MKD',
    phoneCode: '+389',
    svgIcon: 'flag-icon-mk',
    newSvgIcon: 'mk',
    id: '511666c9-2507-43e1-990f-47748c4d783e'
  },
  {
    nombre: 'Madagascar',
    name: 'Madagascar',
    nom: 'Madagascar',
    iso2: 'MG',
    iso3: 'MDG',
    phoneCode: '+261',
    svgIcon: 'flag-icon-mg',
    newSvgIcon: 'mg',
    id: 'dc086fef-960c-4962-b743-fd352f5f993d'
  },
  {
    nombre: 'Malasia',
    name: 'Malaysia',
    nom: 'Malaisie',
    iso2: 'MY',
    iso3: 'MYS',
    phoneCode: '+60',
    svgIcon: 'flag-icon-my',
    newSvgIcon: 'my',
    id: '5a5e4c39-c3af-492c-a2c7-24caf3d39a3f'
  },
  {
    nombre: 'Malawi',
    name: 'Malawi',
    nom: 'Malawi',
    iso2: 'MW',
    iso3: 'MWI',
    phoneCode: '+265',
    svgIcon: 'flag-icon-mw',
    newSvgIcon: 'mw',
    id: '410faa44-1a1d-430c-87d3-f3533dbd9833'
  },
  {
    nombre: 'Mali',
    name: 'Mali',
    nom: 'Mali',
    iso2: 'ML',
    iso3: 'MLI',
    phoneCode: '+223',
    svgIcon: 'flag-icon-ml',
    newSvgIcon: 'ml',
    id: '33c21c04-cdcb-453f-bb6b-d934a0f5e7e5'
  },
  {
    nombre: 'Malta',
    name: 'Malta',
    nom: 'Malte',
    iso2: 'MT',
    iso3: 'MLT',
    phoneCode: '+356',
    svgIcon: 'flag-icon-mt',
    newSvgIcon: 'mt',
    id: '56507e2d-b6bc-444e-a34c-a6725e911894'
  },
  {
    nombre: 'Marruecos',
    name: 'Morocco',
    nom: 'Maroc',
    iso2: 'MA',
    iso3: 'MAR',
    phoneCode: '+212',
    svgIcon: 'flag-icon-ma',
    newSvgIcon: 'ma',
    id: 'ebc337f3-f67e-4af2-b9c5-0ca564a2211b'
  },
  {
    nombre: 'Martinica',
    name: 'Martinique',
    nom: 'Martinique',
    iso2: 'MQ',
    iso3: 'MTQ',
    phoneCode: '+',
    svgIcon: 'flag-icon-mq',
    newSvgIcon: 'mq',
    id: 'f4681b77-b89b-4da6-88f2-d60d2310274d'
  },
  {
    nombre: 'Mauricio',
    name: 'Mauritius',
    nom: 'Iles Maurice',
    iso2: 'MU',
    iso3: 'MUS',
    phoneCode: '+230',
    svgIcon: 'flag-icon-mu',
    newSvgIcon: 'mu',
    id: 'aa640c24-23fb-4801-9c13-5ed8c4fbdbc6'
  },
  {
    nombre: 'Mauritania',
    name: 'Mauritania',
    nom: 'Mauritanie',
    iso2: 'MR',
    iso3: 'MRT',
    phoneCode: '+222',
    svgIcon: 'flag-icon-mr',
    newSvgIcon: 'mr',
    id: '65e2f6e2-659e-4ef3-932a-78dd591be7a1'
  },
  {
    nombre: 'Mayotte',
    name: 'Mayotte',
    nom: 'Mayotte',
    iso2: 'YT',
    iso3: 'MYT',
    phoneCode: '+262',
    svgIcon: 'flag-icon-yt',
    newSvgIcon: 'yt',
    id: '9ab239b6-53d1-4c4f-85fb-80b5818b889b'
  },
  {
    nombre: 'Micronesia',
    name: 'Estados Federados de',
    nom: 'Federados Estados de',
    iso2: 'FM',
    iso3: 'FSM',
    phoneCode: '+691',
    svgIcon: 'flag-icon-fm',
    newSvgIcon: 'fm',
    id: 'bbf2a3df-ef44-485e-8ec6-5a476aa66e5a'
  },
  {
    nombre: 'Moldavia',
    name: 'Moldova',
    nom: 'Moldavie',
    iso2: 'MD',
    iso3: 'MDA',
    phoneCode: '+373',
    svgIcon: 'flag-icon-md',
    newSvgIcon: 'md',
    id: '71f0d231-873c-474e-8717-9e409c7bca43'
  },
  {
    nombre: 'Mongolia',
    name: 'Mongolia',
    nom: 'Mongolie',
    iso2: 'MN',
    iso3: 'MNG',
    phoneCode: '+976',
    svgIcon: 'flag-icon-mn',
    newSvgIcon: 'mn',
    id: '923a337e-8482-45c5-98c7-b29e8bd51461'
  },
  {
    nombre: 'Montenegro',
    name: 'Montenegro',
    nom: 'Monténégro',
    iso2: 'ME',
    iso3: 'MNE',
    phoneCode: '+382',
    svgIcon: 'flag-icon-me',
    newSvgIcon: 'me',
    id: 'abb0f2c8-8493-4d36-b837-85045d7ce3eb'
  },
  {
    nombre: 'Montserrat',
    name: 'Montserrat',
    nom: 'Montserrat',
    iso2: 'MS',
    iso3: 'MSR',
    phoneCode: '+1 664',
    svgIcon: 'flag-icon-ms',
    newSvgIcon: 'ms',
    id: '82aa726d-e79d-4092-b7a8-f54293aa5781'
  },
  {
    nombre: 'Mozambique',
    name: 'Mozambique',
    nom: 'Mozambique',
    iso2: 'MZ',
    iso3: 'MOZ',
    phoneCode: '+258',
    svgIcon: 'flag-icon-mz',
    newSvgIcon: 'mz',
    id: '8ada518d-b8bc-47fa-af59-02d9eafd049c'
  },
  {
    nombre: 'Namibia',
    name: 'Namibia',
    nom: 'Namibie',
    iso2: 'NA',
    iso3: 'NAM',
    phoneCode: '+264',
    svgIcon: 'flag-icon-na',
    newSvgIcon: 'na',
    id: '675c0b60-516b-47a5-ae01-d60cbbc226dd'
  },
  {
    nombre: 'Nauru',
    name: 'Nauru',
    nom: 'Nauru',
    iso2: 'NR',
    iso3: 'NRU',
    phoneCode: '+674',
    svgIcon: 'flag-icon-nr',
    newSvgIcon: 'nr',
    id: 'd08bbfac-28b0-4c2f-bc28-1983cf92bc92'
  },
  {
    nombre: 'Nepal',
    name: 'Nepal',
    nom: 'Népal',
    iso2: 'NP',
    iso3: 'NPL',
    phoneCode: '+977',
    svgIcon: 'flag-icon-np',
    newSvgIcon: 'np',
    id: 'aaffc84c-1d62-403f-b0df-b5b24d5d7505'
  },
  {
    nombre: 'Nicaragua',
    name: 'Nicaragua',
    nom: 'Nicaragua',
    iso2: 'NI',
    iso3: 'NIC',
    phoneCode: '+505',
    svgIcon: 'flag-icon-ni',
    newSvgIcon: 'ni',
    id: 'dcb939fb-c390-4e63-8965-c0dd1426e474'
  },
  {
    nombre: 'Niger',
    name: 'Niger',
    nom: 'Niger',
    iso2: 'NE',
    iso3: 'NER',
    phoneCode: '+227',
    svgIcon: 'flag-icon-ne',
    newSvgIcon: 'ne',
    id: '9bf6a9f4-1c0e-498c-ad32-c79a98b7b0a5'
  },
  {
    nombre: 'Nigeria',
    name: 'Nigeria',
    nom: 'Nigeria',
    iso2: 'NG',
    iso3: 'NGA',
    phoneCode: '+234',
    svgIcon: 'flag-icon-ng',
    newSvgIcon: 'ng',
    id: '21a34329-7ec4-4484-8d2b-749d9268dd5f'
  },
  {
    nombre: 'Niue',
    name: 'Niue',
    nom: 'Niou',
    iso2: 'NU',
    iso3: 'NIU',
    phoneCode: '+683',
    svgIcon: 'flag-icon-nu',
    newSvgIcon: 'nu',
    id: '890d86e3-9e78-467d-b19f-642fd29ca120'
  },
  {
    nombre: 'Noruega',
    name: 'Norway',
    nom: 'Norvège',
    iso2: 'NO',
    iso3: 'NOR',
    phoneCode: '+47',
    svgIcon: 'flag-icon-no',
    newSvgIcon: 'no',
    id: 'e0b59c16-6ec1-48a5-b2b9-c62af97f453d'
  },
  {
    nombre: 'Nueva Caledonia',
    name: 'New Caledonia',
    nom: 'Nouvelle-Calédonie',
    iso2: 'NC',
    iso3: 'NCL',
    phoneCode: '+687',
    svgIcon: 'flag-icon-nc',
    newSvgIcon: 'nc',
    id: '1e755da7-b600-47bb-9fcb-a364e1758bcb'
  },
  {
    nombre: 'Nueva Zelanda',
    name: 'New Zealand',
    nom: 'Nouvelle-Zélande',
    iso2: 'NZ',
    iso3: 'NZL',
    phoneCode: '+64',
    svgIcon: 'flag-icon-nz',
    newSvgIcon: 'nz',
    id: 'ff2d1911-5be9-4767-ac61-814bc1944f89'
  },
  {
    nombre: 'Omán',
    name: 'Oman',
    nom: 'Oman',
    iso2: 'OM',
    iso3: 'OMN',
    phoneCode: '+968',
    svgIcon: 'flag-icon-om',
    newSvgIcon: 'om',
    id: '6103f8d1-a9e9-4c7b-9251-c3fd5a53157f'
  },
  {
    nombre: 'Países Bajos',
    name: 'Netherlands',
    nom: 'Pays-Bas',
    iso2: 'NL',
    iso3: 'NLD',
    phoneCode: '+31',
    svgIcon: 'flag-icon-nl',
    newSvgIcon: 'nl',
    id: 'a9aa020e-7d0d-4574-abe9-c128ad832a48'
  },
  {
    nombre: 'Pakistán',
    name: 'Pakistan',
    nom: 'Pakistan',
    iso2: 'PK',
    iso3: 'PAK',
    phoneCode: '+92',
    svgIcon: 'flag-icon-pk',
    newSvgIcon: 'pk',
    id: '83ff1dbd-c824-4c0c-a788-071b0080f574'
  },
  {
    nombre: 'Palau',
    name: 'Palau',
    nom: 'Palau',
    iso2: 'PW',
    iso3: 'PLW',
    phoneCode: '+680',
    svgIcon: 'flag-icon-pw',
    newSvgIcon: 'pw',
    id: '58fe6e71-7fd8-4699-a4fc-d448e32f6857'
  },
  {
    nombre: 'Palestina',
    name: 'Palestine',
    nom: 'La Palestine',
    iso2: 'PS',
    iso3: 'PSE',
    phoneCode: '+',
    svgIcon: 'flag-icon-ps',
    newSvgIcon: 'ps',
    id: '79d89a73-7918-41ae-8ced-8df7c3fd4180'
  },
  {
    nombre: 'Panamá',
    name: 'Panama',
    nom: 'Panama',
    iso2: 'PA',
    iso3: 'PAN',
    phoneCode: '+507',
    svgIcon: 'flag-icon-pa',
    newSvgIcon: 'pa',
    id: '0406b164-cfc4-40e1-b50a-1603cf4fc80c'
  },
  {
    nombre: 'Papúa Nueva Guinea',
    name: 'Papua New Guinea',
    nom: 'Papouasie-Nouvelle-Guinée',
    iso2: 'PG',
    iso3: 'PNG',
    phoneCode: '+675',
    svgIcon: 'flag-icon-pg',
    newSvgIcon: 'pg',
    id: 'eab5d8e2-950d-4a75-bb21-62020a9d31d3'
  },
  {
    nombre: 'Paraguay',
    name: 'Paraguay',
    nom: 'Paraguay',
    iso2: 'PY',
    iso3: 'PRY',
    phoneCode: '+595',
    svgIcon: 'flag-icon-py',
    newSvgIcon: 'py',
    id: 'f9dc2010-97fc-4712-9f4a-1544212358c6'
  },
  {
    nombre: 'Perú',
    name: 'Peru',
    nom: 'Pérou',
    iso2: 'PE',
    iso3: 'PER',
    phoneCode: '+51',
    svgIcon: 'flag-icon-pe',
    newSvgIcon: 'pe',
    id: 'ce15617c-e851-4dce-a92d-87faa793ae65'
  },
  {
    nombre: 'Polinesia Francesa',
    name: 'French Polynesia',
    nom: 'Polynésie française',
    iso2: 'PF',
    iso3: 'PYF',
    phoneCode: '+689',
    svgIcon: 'flag-icon-pf',
    newSvgIcon: 'pf',
    id: '0179bea5-8ae4-4637-9f20-29df51df5f29'
  },
  {
    nombre: 'Polonia',
    name: 'Poland',
    nom: 'Pologne',
    iso2: 'PL',
    iso3: 'POL',
    phoneCode: '+48',
    svgIcon: 'flag-icon-pl',
    newSvgIcon: 'pl',
    id: '85e02669-f62a-4ab6-8993-407eb81c7f2f'
  },
  {
    nombre: 'Portugal',
    name: 'Portugal',
    nom: 'Portugal',
    iso2: 'PT',
    iso3: 'PRT',
    phoneCode: '+351',
    svgIcon: 'flag-icon-pt',
    newSvgIcon: 'pt',
    id: '4378a2ba-964b-42fa-a043-6361fcd41b3a'
  },
  {
    nombre: 'Puerto Rico',
    name: 'Puerto Rico',
    nom: 'Porto Rico',
    iso2: 'PR',
    iso3: 'PRI',
    phoneCode: '+1',
    svgIcon: 'flag-icon-pr',
    newSvgIcon: 'pr',
    id: '90880efc-ed28-4315-9e04-4b1710e0fb0c'
  },
  {
    nombre: 'Qatar',
    name: 'Qatar',
    nom: 'Qatar',
    iso2: 'QA',
    iso3: 'QAT',
    phoneCode: '+974',
    svgIcon: 'flag-icon-qa',
    newSvgIcon: 'qa',
    id: '0a2be729-d5af-4cee-9d08-17f24abb2b62'
  },
  {
    nombre: 'Reino Unido',
    name: 'United Kingdom',
    nom: 'Royaume-Uni',
    iso2: 'GB',
    iso3: 'GBR',
    phoneCode: '+44',
    svgIcon: 'flag-icon-gb',
    newSvgIcon: 'gb',
    id: '7be584f7-a912-4337-81e7-7f337af52f68'
  },
  {
    nombre: 'República Centroafricana',
    name: 'Central African Republic',
    nom: 'République Centrafricaine',
    iso2: 'CF',
    iso3: 'CAF',
    phoneCode: '+236',
    svgIcon: 'flag-icon-cf',
    newSvgIcon: 'cf',
    id: '96bcc7cd-5fd5-41cb-8046-3e6bb7bf9810'
  },
  {
    nombre: 'República Checa',
    name: 'Czech Republic',
    nom: 'République Tchèque',
    iso2: 'CZ',
    iso3: 'CZE',
    phoneCode: '+420',
    svgIcon: 'flag-icon-cz',
    newSvgIcon: 'cz',
    id: '85ad3746-1798-431d-bd1d-2dd349da089f'
  },
  {
    nombre: 'República Dominicana',
    name: 'Dominican Republic',
    nom: 'République Dominicaine',
    iso2: 'DO',
    iso3: 'DOM',
    phoneCode: '+1 809',
    svgIcon: 'flag-icon-do',
    newSvgIcon: 'do',
    id: 'f2924d53-7a49-49a3-928c-a2c2b689d80c'
  },
  {
    nombre: 'Reunión',
    name: 'Réunion',
    nom: 'Réunion',
    iso2: 'RE',
    iso3: 'REU',
    phoneCode: '+',
    svgIcon: 'flag-icon-re',
    newSvgIcon: 're',
    id: '6d5d8fde-9200-410c-a4b6-30d28ae774fe'
  },
  {
    nombre: 'Ruanda',
    name: 'Rwanda',
    nom: 'Rwanda',
    iso2: 'RW',
    iso3: 'RWA',
    phoneCode: '+250',
    svgIcon: 'flag-icon-rw',
    newSvgIcon: 'rw',
    id: 'da876428-3406-4a56-ae5b-6ae7e64605d0'
  },
  {
    nombre: 'Rumanía',
    name: 'Romania',
    nom: 'Roumanie',
    iso2: 'RO',
    iso3: 'ROU',
    phoneCode: '+40',
    svgIcon: 'flag-icon-ro',
    newSvgIcon: 'ro',
    id: 'ee1c2b21-9db6-4b96-b44d-583bcca7801d'
  },
  {
    nombre: 'Rusia',
    name: 'Russia',
    nom: 'La Russie',
    iso2: 'RU',
    iso3: 'RUS',
    phoneCode: '+7',
    svgIcon: 'flag-icon-ru',
    newSvgIcon: 'ru',
    id: '900247aa-22d4-48f0-8195-3108e8572ffc'
  },
  {
    nombre: 'Sahara Occidental',
    name: 'Western Sahara',
    nom: 'Sahara Occidental',
    iso2: 'EH',
    iso3: 'ESH',
    phoneCode: '+',
    svgIcon: 'flag-icon-eh',
    newSvgIcon: 'eh',
    id: 'eecd91f5-54df-4dbe-94d1-90013a636b17'
  },
  {
    nombre: 'Samoa',
    name: 'Samoa',
    nom: 'Samoa',
    iso2: 'WS',
    iso3: 'WSM',
    phoneCode: '+685',
    svgIcon: 'flag-icon-ws',
    newSvgIcon: 'ws',
    id: '78e8d723-9513-4e7c-9b22-b514d405f741'
  },
  {
    nombre: 'Samoa Americana',
    name: 'American Samoa',
    nom: 'Les Samoa américaines',
    iso2: 'AS',
    iso3: 'ASM',
    phoneCode: '+1 684',
    svgIcon: 'flag-icon-as',
    newSvgIcon: 'as',
    id: 'ab3c3c5a-efd7-4299-ab52-41b0d32a788c'
  },
  {
    nombre: 'San Bartolomé',
    name: 'Saint Barthélemy',
    nom: 'Saint-Barthélemy',
    iso2: 'BL',
    iso3: 'BLM',
    phoneCode: '+590',
    svgIcon: 'flag-icon-bl',
    newSvgIcon: 'bl',
    id: '4ee50101-7d4a-4445-b076-400e2e293753'
  },
  {
    nombre: 'San Cristóbal y Nieves',
    name: 'Saint Kitts and Nevis',
    nom: 'Saint Kitts et Nevis',
    iso2: 'KN',
    iso3: 'KNA',
    phoneCode: '+1 869',
    svgIcon: 'flag-icon-kn',
    newSvgIcon: 'kn',
    id: 'a4199718-9ddd-4d0b-a800-960bb1d6f00f'
  },
  {
    nombre: 'San Marino',
    name: 'San Marino',
    nom: 'San Marino',
    iso2: 'SM',
    iso3: 'SMR',
    phoneCode: '+378',
    svgIcon: 'flag-icon-sm',
    newSvgIcon: 'sm',
    id: '217a58a5-1624-427d-aea9-5cd019c2ddcf'
  },
  {
    nombre: 'San Martín (Francia)',
    name: 'Saint Martin (French part)',
    nom: 'Saint-Martin (partie française)',
    iso2: 'MF',
    iso3: 'MAF',
    phoneCode: '+1 599',
    svgIcon: 'flag-icon-mf',
    newSvgIcon: 'mf',
    id: 'cc71a3c0-c1bf-4747-becf-2125b35855fe'
  },
  {
    nombre: 'San Pedro y Miquelón',
    name: 'Saint Pierre and Miquelon',
    nom: 'Saint-Pierre-et-Miquelon',
    iso2: 'PM',
    iso3: 'SPM',
    phoneCode: '+508',
    svgIcon: 'flag-icon-pm',
    newSvgIcon: 'pm',
    id: 'fc823146-1009-45d5-bb39-46390da714ac'
  },
  {
    nombre: 'San Vicente y las Granadinas',
    name: 'Saint Vincent and the Grenadines',
    nom: 'Saint-Vincent et Grenadines',
    iso2: 'VC',
    iso3: 'VCT',
    phoneCode: '+1 784',
    svgIcon: 'flag-icon-vc',
    newSvgIcon: 'vc',
    id: '21b394ea-39ff-4a24-91ca-f47b8b7c2338'
  },
  {
    nombre: 'Santa Elena',
    name: 'Ascensión y Tristán de Acuña',
    nom: 'Ascensión y Tristan de Acuña',
    iso2: 'SH',
    iso3: 'SHN',
    phoneCode: '+290',
    svgIcon: 'flag-icon-sh',
    newSvgIcon: 'sh',
    id: '3f676550-c24a-4f99-8875-12ed9e580213'
  },
  {
    nombre: 'Santa Lucía',
    name: 'Saint Lucia',
    nom: 'Sainte-Lucie',
    iso2: 'LC',
    iso3: 'LCA',
    phoneCode: '+1 758',
    svgIcon: 'flag-icon-lc',
    newSvgIcon: 'lc',
    id: '8026fed5-d0f9-4409-bcfd-a1382c3f41ff'
  },
  {
    nombre: 'Santo Tomé y Príncipe',
    name: 'Sao Tome and Principe',
    nom: 'Sao Tomé et Principe',
    iso2: 'ST',
    iso3: 'STP',
    phoneCode: '+239',
    svgIcon: 'flag-icon-st',
    newSvgIcon: 'st',
    id: 'bc4cea2e-3562-47b9-aa33-6b094cf4763d'
  },
  {
    nombre: 'Senegal',
    name: 'Senegal',
    nom: 'Sénégal',
    iso2: 'SN',
    iso3: 'SEN',
    phoneCode: '+221',
    svgIcon: 'flag-icon-sn',
    newSvgIcon: 'sn',
    id: '4a60704c-c272-49fa-b4d8-0cee556b393d'
  },
  {
    nombre: 'Serbia',
    name: 'Serbia',
    nom: 'Serbie',
    iso2: 'RS',
    iso3: 'SRB',
    phoneCode: '+381',
    svgIcon: 'flag-icon-rs',
    newSvgIcon: 'rs',
    id: 'f10a7e38-f41f-4af6-ad45-21370d03a25d'
  },
  {
    nombre: 'Seychelles',
    name: 'Seychelles',
    nom: 'Les Seychelles',
    iso2: 'SC',
    iso3: 'SYC',
    phoneCode: '+248',
    svgIcon: 'flag-icon-sc',
    newSvgIcon: 'sc',
    id: '68fdde37-df13-4a58-9e02-875b95cb97c8'
  },
  {
    nombre: 'Sierra Leona',
    name: 'Sierra Leone',
    nom: 'Sierra Leone',
    iso2: 'SL',
    iso3: 'SLE',
    phoneCode: '+232',
    svgIcon: 'flag-icon-sl',
    newSvgIcon: 'sl',
    id: '73048ecb-0cf7-40e5-bb7f-cbcce87a1145'
  },
  {
    nombre: 'Singapur',
    name: 'Singapore',
    nom: 'Singapour',
    iso2: 'SG',
    iso3: 'SGP',
    phoneCode: '+65',
    svgIcon: 'flag-icon-sg',
    newSvgIcon: 'sg',
    id: '136281bb-375a-4add-a8d4-e53277b1ccc5'
  },
  {
    nombre: 'Siria',
    name: 'Syria',
    nom: 'Syrie',
    iso2: 'SY',
    iso3: 'SYR',
    phoneCode: '+963',
    svgIcon: 'flag-icon-sy',
    newSvgIcon: 'sy',
    id: '1a709e48-b10c-4bdc-8340-73359f480756'
  },
  {
    nombre: 'Somalia',
    name: 'Somalia',
    nom: 'Somalie',
    iso2: 'SO',
    iso3: 'SOM',
    phoneCode: '+252',
    svgIcon: 'flag-icon-so',
    newSvgIcon: 'so',
    id: 'e0332419-f279-4943-a5a4-52a05255a867'
  },
  {
    nombre: 'Sri lanka',
    name: 'Sri Lanka',
    nom: 'Sri Lanka',
    iso2: 'LK',
    iso3: 'LKA',
    phoneCode: '+94',
    svgIcon: 'flag-icon-lk',
    newSvgIcon: 'lk',
    id: '93a78cdb-3b97-43fe-9811-a55608ecc7a1'
  },
  {
    nombre: 'Sudáfrica',
    name: 'South Africa',
    nom: 'Afrique du Sud',
    iso2: 'ZA',
    iso3: 'ZAF',
    phoneCode: '+27',
    svgIcon: 'flag-icon-za',
    newSvgIcon: 'za',
    id: 'd1adbde6-3582-4d85-89cd-2d51e9e82b2b'
  },
  {
    nombre: 'Sudán',
    name: 'Sudan',
    nom: 'Soudan',
    iso2: 'SD',
    iso3: 'SDN',
    phoneCode: '+249',
    svgIcon: 'flag-icon-sd',
    newSvgIcon: 'sd',
    id: '69a2ebb3-8a51-4f1d-a678-6f2b60150a59'
  },
  {
    nombre: 'Suecia',
    name: 'Sweden',
    nom: 'Suède',
    iso2: 'SE',
    iso3: 'SWE',
    phoneCode: '+46',
    svgIcon: 'flag-icon-se',
    newSvgIcon: 'se',
    id: '56178a66-dfd3-456c-8446-8f4cf0b9d754'
  },
  {
    nombre: 'Suiza',
    name: 'Switzerland',
    nom: 'Suisse',
    iso2: 'CH',
    iso3: 'CHE',
    phoneCode: '+41',
    svgIcon: 'flag-icon-ch',
    newSvgIcon: 'ch',
    id: '0e926d04-80ad-4e8e-8a72-29d6fe465cbd'
  },
  {
    nombre: 'Surinám',
    name: 'Suriname',
    nom: 'Surinam',
    iso2: 'SR',
    iso3: 'SUR',
    phoneCode: '+597',
    svgIcon: 'flag-icon-sr',
    newSvgIcon: 'sr',
    id: '0704de11-5d85-4628-82ab-63c6e31c1067'
  },
  {
    nombre: 'Svalbard y Jan Mayen',
    name: 'Svalbard and Jan Mayen',
    nom: 'Svalbard et Jan Mayen',
    iso2: 'SJ',
    iso3: 'SJM',
    phoneCode: '+',
    svgIcon: 'flag-icon-sj',
    newSvgIcon: 'sj',
    id: '4c84eb1c-3d62-4b43-830a-7c1105d11385'
  },
  {
    nombre: 'Swazilandia',
    name: 'Swaziland',
    nom: 'Swaziland',
    iso2: 'SZ',
    iso3: 'SWZ',
    phoneCode: '+268',
    svgIcon: 'flag-icon-sz',
    newSvgIcon: 'sz',
    id: '350046d9-d4ee-40c5-9716-563991ead7d0'
  },
  {
    nombre: 'Tadjikistán',
    name: 'Tajikistan',
    nom: 'Le Tadjikistan',
    iso2: 'TJ',
    iso3: 'TJK',
    phoneCode: '+992',
    svgIcon: 'flag-icon-tj',
    newSvgIcon: 'tj',
    id: '1e4b2c9a-2026-4c38-b82c-9acf8f1ca586'
  },
  {
    nombre: 'Tailandia',
    name: 'Thailand',
    nom: 'Thaïlande',
    iso2: 'TH',
    iso3: 'THA',
    phoneCode: '+66',
    svgIcon: 'flag-icon-th',
    newSvgIcon: 'th',
    id: 'a3b73031-f08a-43b2-aa21-66df4d91a459'
  },
  {
    nombre: 'Taiwán',
    name: 'Taiwan',
    nom: 'Taiwan',
    iso2: 'TW',
    iso3: 'TWN',
    phoneCode: '+886',
    svgIcon: 'flag-icon-tw',
    newSvgIcon: 'tw',
    id: '342aac69-4453-4c57-9615-f37d2bfeeeab'
  },
  {
    nombre: 'Tanzania',
    name: 'Tanzania',
    nom: 'Tanzanie',
    iso2: 'TZ',
    iso3: 'TZA',
    phoneCode: '+255',
    svgIcon: 'flag-icon-tz',
    newSvgIcon: 'tz',
    id: '8950ccc0-8c46-4081-ab2a-8e9f9781501d'
  },
  {
    nombre: 'Territorio Británico del Océano Índico',
    name: 'British Indian Ocean Territory',
    nom: "Territoire britannique de l'océan Indien",
    iso2: 'IO',
    iso3: 'IOT',
    phoneCode: '+',
    svgIcon: 'flag-icon-io',
    newSvgIcon: 'io',
    id: '18ea37bf-1942-4b5d-ad75-4e54a038212f'
  },
  {
    nombre: 'Territorios Australes y Antárticas Franceses',
    name: 'French Southern Territories',
    nom: 'Terres australes françaises',
    iso2: 'TF',
    iso3: 'ATF',
    phoneCode: '+',
    svgIcon: 'flag-icon-tf',
    newSvgIcon: 'tf',
    id: 'd9eeeaab-957a-4700-b6a9-d9a9e760976e'
  },
  {
    nombre: 'Timor Oriental',
    name: 'East Timor',
    nom: 'Timor-Oriental',
    iso2: 'TL',
    iso3: 'TLS',
    phoneCode: '+670',
    svgIcon: 'flag-icon-tl',
    newSvgIcon: 'tl',
    id: '7dc3b2e7-cf90-4709-afab-311a3397ba67'
  },
  {
    nombre: 'Togo',
    name: 'Togo',
    nom: 'Togo',
    iso2: 'TG',
    iso3: 'TGO',
    phoneCode: '+228',
    svgIcon: 'flag-icon-tg',
    newSvgIcon: 'tg',
    id: '0824f5b8-7bd4-47c5-8962-7847423e1cb9'
  },
  {
    nombre: 'Tokelau',
    name: 'Tokelau',
    nom: 'Tokélaou',
    iso2: 'TK',
    iso3: 'TKL',
    phoneCode: '+690',
    svgIcon: 'flag-icon-tk',
    newSvgIcon: 'tk',
    id: 'ffe35136-141d-415a-877c-1769ac9f999b'
  },
  {
    nombre: 'Tonga',
    name: 'Tonga',
    nom: 'Tonga',
    iso2: 'TO',
    iso3: 'TON',
    phoneCode: '+676',
    svgIcon: 'flag-icon-to',
    newSvgIcon: 'to',
    id: '94aa9213-1174-4d6f-9c00-bfdde859189a'
  },
  {
    nombre: 'Trinidad y Tobago',
    name: 'Trinidad and Tobago',
    nom: 'Trinidad et Tobago',
    iso2: 'TT',
    iso3: 'TTO',
    phoneCode: '+1 868',
    svgIcon: 'flag-icon-tt',
    newSvgIcon: 'tt',
    id: '1b14aad9-9dbb-4870-a07c-0ad3b4b9f0cb'
  },
  {
    nombre: 'Tunez',
    name: 'Tunisia',
    nom: 'Tunisie',
    iso2: 'TN',
    iso3: 'TUN',
    phoneCode: '+216',
    svgIcon: 'flag-icon-tn',
    newSvgIcon: 'tn',
    id: '96d6cc6e-f924-4b10-b1c7-93aedd6e1957'
  },
  {
    nombre: 'Turkmenistán',
    name: 'Turkmenistan',
    nom: 'Le Turkménistan',
    iso2: 'TM',
    iso3: 'TKM',
    phoneCode: '+993',
    svgIcon: 'flag-icon-tm',
    newSvgIcon: 'tm',
    id: '518ce69c-d054-4814-9722-8e9a5e2f07bf'
  },
  {
    nombre: 'Turquía',
    name: 'Turkey',
    nom: 'Turquie',
    iso2: 'TR',
    iso3: 'TUR',
    phoneCode: '+90',
    svgIcon: 'flag-icon-tr',
    newSvgIcon: 'tr',
    id: 'daa5cfaa-e977-4e33-8cc9-94e74c6ebc67'
  },
  {
    nombre: 'Tuvalu',
    name: 'Tuvalu',
    nom: 'Tuvalu',
    iso2: 'TV',
    iso3: 'TUV',
    phoneCode: '+688',
    svgIcon: 'flag-icon-tv',
    newSvgIcon: 'tv',
    id: 'e00ab082-e925-4ba7-bd4f-7a9b034f8fea'
  },
  {
    nombre: 'Ucrania',
    name: 'Ukraine',
    nom: "L'Ukraine",
    iso2: 'UA',
    iso3: 'UKR',
    phoneCode: '+380',
    svgIcon: 'flag-icon-ua',
    newSvgIcon: 'ua',
    id: '049448c3-0c7c-4aac-83a7-ae4d41f7f947'
  },
  {
    nombre: 'Uganda',
    name: 'Uganda',
    nom: 'Ouganda',
    iso2: 'UG',
    iso3: 'UGA',
    phoneCode: '+256',
    svgIcon: 'flag-icon-ug',
    newSvgIcon: 'ug',
    id: '32c2966c-329b-4c72-85f4-07f424e3ce28'
  },
  {
    nombre: 'Uruguay',
    name: 'Uruguay',
    nom: 'Uruguay',
    iso2: 'UY',
    iso3: 'URY',
    phoneCode: '+598',
    svgIcon: 'flag-icon-uy',
    newSvgIcon: 'uy',
    id: '58e58fdc-8721-47e2-974e-a02f5529fdaf'
  },
  {
    nombre: 'Uzbekistán',
    name: 'Uzbekistan',
    nom: "L'Ouzbékistan",
    iso2: 'UZ',
    iso3: 'UZB',
    phoneCode: '+998',
    svgIcon: 'flag-icon-uz',
    newSvgIcon: 'uz',
    id: 'c8ff7e1b-a2c0-47a8-b030-8d06625aa413'
  },
  {
    nombre: 'Vanuatu',
    name: 'Vanuatu',
    nom: 'Vanuatu',
    iso2: 'VU',
    iso3: 'VUT',
    phoneCode: '+678',
    svgIcon: 'flag-icon-vu',
    newSvgIcon: 'vu',
    id: '2bcb2a5b-766c-44e9-860b-16afdb80d147'
  },
  {
    nombre: 'Venezuela',
    name: 'Venezuela',
    nom: 'Venezuela',
    iso2: 'VE',
    iso3: 'VEN',
    phoneCode: '+58',
    svgIcon: 'flag-icon-ve',
    newSvgIcon: 've',
    id: 'ff6f5f0a-365a-48c7-b5c3-3d6328124dd1'
  },
  {
    nombre: 'Vietnam',
    name: 'Vietnam',
    nom: 'Vietnam',
    iso2: 'VN',
    iso3: 'VNM',
    phoneCode: '+84',
    svgIcon: 'flag-icon-vn',
    newSvgIcon: 'vn',
    id: 'bf60a6c7-326c-4d83-89bb-acdcb083ccfa'
  },
  {
    nombre: 'Wallis y Futuna',
    name: 'Wallis and Futuna',
    nom: 'Wallis et Futuna',
    iso2: 'WF',
    iso3: 'WLF',
    phoneCode: '+681',
    svgIcon: 'flag-icon-wf',
    newSvgIcon: 'wf',
    id: 'b696a25e-14c1-481a-b1b2-b468c3067a66'
  },
  {
    nombre: 'Yemen',
    name: 'Yemen',
    nom: 'Yémen',
    iso2: 'YE',
    iso3: 'YEM',
    phoneCode: '+967',
    svgIcon: 'flag-icon-ye',
    newSvgIcon: 'ye',
    id: 'c19dfe05-0539-4ffc-9509-b9e5819c9b05'
  },
  {
    nombre: 'Yibuti',
    name: 'Djibouti',
    nom: 'Djibouti',
    iso2: 'DJ',
    iso3: 'DJI',
    phoneCode: '+253',
    svgIcon: 'flag-icon-dj',
    newSvgIcon: 'dj',
    id: '29b97d8e-d0d2-4b55-a14b-bbd75d7fbdc4'
  },
  {
    nombre: 'Zambia',
    name: 'Zambia',
    nom: 'Zambie',
    iso2: 'ZM',
    iso3: 'ZMB',
    phoneCode: '+260',
    svgIcon: 'flag-icon-zm',
    newSvgIcon: 'zm',
    id: 'ff84190b-2583-4c98-b72f-3295ee6e0525'
  },
  {
    nombre: 'Zimbabue',
    name: 'Zimbabwe',
    nom: 'Zimbabwe',
    iso2: 'ZW',
    iso3: 'ZWE',
    phoneCode: '+263',
    svgIcon: 'flag-icon-zw',
    newSvgIcon: 'zw',
    id: 'f9f91e6a-bdbb-488b-8c3d-cfee5b8d3b22'
  }
]